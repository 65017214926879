import { actions as appActions } from "./app";
import { actions as coreActions } from "./core";
import { actions as listActions } from "./lists";
import { actions as visualizationTypeActions } from "./visualizationType";

const exports = {
    appActions,
    coreActions,
    listActions,
    visualizationTypeActions
};

export default exports;

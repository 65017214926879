import React from "react";
import TextBlock from "../../components/TextBlock";
import StackedInfo from "../../components/StackedInfo";

import {
    ObservatoryContext,
} from "@gometro/mobility-observatory-visualization-module/dist/index.js";
import LanguageContext from "../../../common/contexts/LanguageContext";

function mapAxisToSvg(axis) {
    switch (axis) {
        case "mobility":
            return "mobility";

        case "experience":
            return "experience";

        case "efficiency":
            return "efficiency";

        default:
            return "mobility";
    }
}
const ObservatoryInfo = ({ axis, city, indicator }) => {
    const context = React.useContext(ObservatoryContext);
    const {translate} = React.useContext(LanguageContext)
    return (
        <>
            <TextBlock
                svg={mapAxisToSvg(axis.value)}
                title={translate(axis.label)}
                type="info"
                sections={[
                    translate(`info.${indicator.label}`),
                    translate(`info.surveys.${indicator.label}`),
                    translate(`info.uma.${indicator.label}`),
                ]}
            />
            <div className="c-text-block">
                <h3 className="c-text-block__title">
                    {translate(city.label)} {translate("info.summary")}
                </h3>
                <StackedInfo
                    mainContent={context.getPopulation()}
                    subContent={translate("info.population")}
                    icon={"cidPeople"}
                />
                <StackedInfo
                    mainContent={context.getZonesSurveyed()}
                    subContent={translate("info.zones-surveyed")}
                    icon={"chartArea"}
                />
            </div>
        </>
    );
};

export default ObservatoryInfo;

export const crimeOnPt = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.33 23.03">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g id="Group_3012" data-name="Group 3012">
    <path id="Path_3661" data-name="Path 3661" class="cls-1" d="M6.24,4.37c-1-1.22-.91-2.49.13-3.28a2.1,2.1,0,0,1,2.93.47,2.13,2.13,0,0,1,.16.25L10,.58c.29-.62.52-.71,1.13-.43l3.22,1.46a.47.47,0,0,0,.51,0,7.6,7.6,0,1,1,1.75,13.85c-.14,0-.27-.06-.28-.27A8.71,8.71,0,0,0,15.64,12l0-.17a4.8,4.8,0,0,0,3.84,1.09,4.62,4.62,0,0,0,2.78-1.46,4.79,4.79,0,0,0-4.91-7.85,4.72,4.72,0,0,0-2.8,2.31,4.88,4.88,0,0,0-.37,3.67c-.43-.37-.81-.7-1.17-1a.55.55,0,0,1-.07-.31c0-1,0-2,0-3a1.81,1.81,0,0,0-1.66-2,1.6,1.6,0,0,0-.31,0c-.43,0-.86,0-1.24,0L9,4.37h2a.61.61,0,0,1,.7.52.48.48,0,0,1,0,.17c0,1.23,0,2.47,0,3.7a.5.5,0,0,0,.24.46A7.6,7.6,0,1,1,0,15,7.44,7.44,0,0,1,3.23,9.22a.48.48,0,0,0,.23-.43c0-1.2,0-2.4,0-3.61,0-.61.2-.81.8-.82Zm6.15,11.1a4.79,4.79,0,1,0-4.81,4.76h0a4.8,4.8,0,0,0,4.78-4.76Zm-5.48-12c.39-.62.87-.61,1.42,0A1,1,0,0,0,8.29,2h0a1,1,0,0,0-1.41.05,1,1,0,0,0,0,1.35Zm1,3.66c.4-.42.46-.8.18-1.11a.68.68,0,0,0-1-.06c-.35.3-.31.65.12,1.19l-.2.39c-.1.21,0,.32.2.32h.66c.25,0,.29-.12.2-.32l-.2-.41Z"/>
  </g>
</svg>`];


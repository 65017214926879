export const periodOfTravel = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.13 23.19">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <path id="Path_3672" data-name="Path 3672" class="cls-1" d="M.44,14.76A11.29,11.29,0,0,1,0,11.2l2,.07V12a9.06,9.06,0,0,0,.24,1.79c0,.1,0,.15-.09.19Z"/>
    <path id="Path_3673" data-name="Path 3673" class="cls-1" d="M17.32,21.65l.82,1.54-4.72-1.13,2.26-3.46.7,1.3a9.62,9.62,0,0,0,.09-16.52,8.66,8.66,0,0,0-2.8-1.14,9.64,9.64,0,0,0-3-.19,9.48,9.48,0,0,0-2.92.77A9.7,9.7,0,0,0,5.17,4.47L3.83,3A11.4,11.4,0,0,1,14,.28a11.6,11.6,0,0,1,8.9,13.6,11.62,11.62,0,0,1-5.59,7.77Z"/>
    <path id="Path_3674" data-name="Path 3674" class="cls-1" d="M11.6,2.93a8.68,8.68,0,1,0,8.67,8.67h0A8.67,8.67,0,0,0,11.6,2.93Zm4.77,9.49h-4.1a.22.22,0,0,0-.2.09,1.06,1.06,0,0,1-1.11.32,1,1,0,0,1-.76-1.24,1.05,1.05,0,0,1,.46-.63.33.33,0,0,0,.18-.32c0-2,0-3.92,0-5.89,0-.11,0-.14.14-.14h.65v6.08a.18.18,0,0,0,.11.2l.35.26a.41.41,0,0,0,.19.08h4.1Z"/>
    <path id="Path_3675" data-name="Path 3675" class="cls-1" d="M3.75,6.06A9.74,9.74,0,0,0,2.2,9.63L.27,9.11a11.56,11.56,0,0,1,1.85-4.2Z"/>
    <path id="Path_3676" data-name="Path 3676" class="cls-1" d="M2.66,15.22c.25.45.46.9.71,1.32s.56.82.84,1.25L2.73,19.08l-.38-.48a12,12,0,0,1-1.27-2.09.16.16,0,0,1,.06-.24Z"/>
    <path id="Path_3677" data-name="Path 3677" class="cls-1" d="M4.63,20.87l1.16-1.62a10.09,10.09,0,0,0,2.36,1.31L7.4,22.41A11.39,11.39,0,0,1,4.63,20.87Z"/>
    <path id="Path_3678" data-name="Path 3678" class="cls-1" d="M9.54,23c.13-.67.25-1.31.37-2,.24,0,.48.07.71.08.4,0,.8,0,1.2.05.1,0,.14,0,.17.12.19.61.38,1.21.58,1.83A11.83,11.83,0,0,1,9.54,23Z"/>
  </g>
</svg>`];


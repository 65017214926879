import React from "react";
import { CCol, CRow } from "@coreui/react";
import ObservatoryGraph from "../../components/ObservatoryGraph";
import ObservatoryMap from "../../components/ObservatoryMap";
import { connect } from "react-redux";
import ObservatoryInfo from "./ObservatoryInfo";
import { ObservatoryContext } from "@gometro/mobility-observatory-visualization-module/dist/index.js";
import Loader from "../../components/Loader/Loader";

function getEnabledIndicatorsForAxis(axis, list) {
    const indicators = list.filter((v) => v.value === axis);
    if (indicators.length === 0) {
        return [];
    }
    return indicators[0].indicators;
}

function getDisabledIndicatorsForAxis(axis, list) {
    const indicators = list.filter((v) => v.value === axis);
    if (indicators.length === 0) {
        return [];
    }
    return indicators[0].inactiveIndicators;
}

const Observatory = ({
                         onIndicatorChange,
                         onOptionChange,
                         axis,
                         option,
                         indicator,
                         city,
                         zone,
                         axisList,
                         axisLabel,
                         visualisationType
                     }) => {
    const context = React.useContext(ObservatoryContext);
    const isLoading = context.isNetworkLoading();

    return (
        <div className={"p-observatory"}>
            <div
                className={`p-observatory__loader ${
                    isLoading ? "m-visible" : "m-hidden"
                }`}
            >
                <div className="p-observatory__loader__inner">
                    <Loader active={isLoading} />
                </div>
            </div>
            <CRow gutters={false}>
                <CCol xs={"4"}>
                    <div className="p-observatory__section--lt">
                        <ObservatoryInfo axis={axis} city={city} indicator={indicator} axisLabel={axisLabel} />
                    </div>
                </CCol>
                <CCol xs={"8"}>
                    <div className="p-observatory__section px-0">
                        {visualisationType === "graph" ? (
                            <ObservatoryGraph
                                    onIndicatorChange={onIndicatorChange}
                                    onOptionChange={onOptionChange}
                                    option={option}
                                    enabledIndicatorList={getEnabledIndicatorsForAxis(axis.value, axisList)}
                                    disabledIndicatorList={getDisabledIndicatorsForAxis(axis.value, axisList)}
                                    indicator={indicator.value}
                                    city={city}
                                    zone={zone}
                                    visualisationType={visualisationType}
                                />
                            ) : (
                            <ObservatoryMap
                                onIndicatorChange={onIndicatorChange}
                                onOptionChange={onOptionChange}
                                option={option}
                                enabledIndicatorList={getEnabledIndicatorsForAxis(axis.value, axisList)}
                                disabledIndicatorList={getDisabledIndicatorsForAxis(axis.value, axisList)}
                                indicator={indicator.value}
                                city={city}
                                zone={zone}
                                visualisationType={visualisationType}
                            />
                        )}
                    </div>
                </CCol>
            </CRow>
        </div>
    );
};

const mapStateToProps = (state) => ({
    axisList: state.observatory.core.axis,
    visualisationType: state.observatory.visualizationType
});

export default connect(mapStateToProps)(Observatory);

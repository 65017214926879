import { actions as authentication } from "./authentication";
import { actions as layout } from "./layout";
import { actions as app } from "./app";

const exports = {
    authentication,
    layout,
    app,
};

export default exports;

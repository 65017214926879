export var colors = [
    "#2f70d0",
    "#ffd799",
    "#a3a3a3",
    "#ffc500",
    "#f4a261",
    "#2a9d8e",
    "#e76f50",
    "#2fcad0",
    "#50ad50",
    "#ca2fd0",
];

export function getLegend(indicator, result, translateLabel) {
    switch(indicator) {
        case "travel_time": return travelTime.map((e) => translateLabel(e));
        case "distance_travelled": return distanceTraveled.map((e) => translateLabel(e));
        case "mode_share":
        default:
            return generateLegend(result);
    }
}

function generateLegend(result) {

    // find unique indicator labels accross all results
    // find maximums
    let uniqueIndicatorList = {};
    result.forEach((resultItem) => {
        resultItem.indicator.forEach((e) => {
            let existingEntry = uniqueIndicatorList[e.label];
            if(!existingEntry || (existingEntry.value && e.value && existingEntry.value < e.value)) {
                uniqueIndicatorList[e.label] = e.value;
            }
        });
    });

    // gather these into legend items
    let legend = Object.keys(uniqueIndicatorList).map((key) => {
        return {
            label: key,
            value: uniqueIndicatorList[key]
        };
    });

    // sort these from high to low
    legend.sort((a, b) => {
        if(Number.isNaN(parseInt(a.label)) || Number.isNaN(parseInt(b.label))) { return 0; }
        return a > b ? 1 : -1;
    });

    // assign colors / generate colors
    let colorIndex = 0;
    legend.forEach((e) => {
        e.color = colors[colorIndex++ % colors.length];
    });

    return legend;
}

const travelTime = [
    {
        label: "indicator.travel_time.100min_plus", // @todo
        color: "#fd2c24",
        test(value) {
            return value >= 100;
        },
    },
    {
        label: "indicator.travel_time.81_100min",
        color: "#fa9393",
        test(value) {
            return value >= 81 && value < 100;
        },
    },
    {
        label: "indicator.travel_time.61_80min",
        color: "#e7aa35",
        test(value) {
            return value >= 61 && value < 81;
        },
    },
    {
        label: "indicator.travel_time.41_60min",
        color: "#f6de9c",
        test(value) {
            return value >= 41 && value < 61;
        },
    },
    {
        label: "indicator.travel_time.21_40min",
        color: "#fcfc9b",
        test(value) {
            return value >= 21 && value < 41;
        },
    },
    {
        label: "indicator.travel_time.0_20min",
        color: "#ffffcc",
        test(value) {
            return value < 21;
        },
    },
];

const distanceTraveled = [
    {
        label: "indicator.distance_travelled.160km_plus",
        color: "#fd2c24",
        test(value) {
            return value >= 160;
        },
    },
    {
        label: "indicator.distance_travelled.80_159km",
        color: "#fa9393",
        test(value) {
            return value >= 80 && value < 160;
        },
    },
    {
        label: "indicator.distance_travelled.40_79km",
        color: "#e7aa35",
        test(value) {
            return value >= 40 && value < 80;
        },
    },
    {
        label: "indicator.distance_travelled.20_39km",
        color: "#f6de9c",
        test(value) {
            return value >= 20 && value < 40;
        },
    },
    {
        label: "indicator.distance_travelled.10_19km",
        color: "#fcfc9b",
        test(value) {
            return value >= 10 && value < 20;
        },
    },
    {
        label: "indicator.distance_travelled.3_9km",
        color: "#ffffcc",
        test(value) {
            return value >= 3 && value < 10;
        },
    },
    {
        label: "indicator.distance_travelled.0_2km",
        color: "#ffffcc",
        test(value) {
            return value < 3;
        },
    },
];
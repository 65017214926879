export const dropdownChange = ["60 60",`
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.55 128.55">
    <defs>
        <style>
            .cls-1 {
            fill: #3c3c3b;
        }

            .cls-2 {
            fill: none;
        }
        </style>
    </defs>
    <g>
        <path id="Icon_awesome-exchange-alt" data-name="Icon awesome-exchange-alt" className="cls-1"
              d="M29.78,53.66V51.5A3.25,3.25,0,0,1,33,48.25H81.66V41.76a3.25,3.25,0,0,1,5.54-2.29L98,50.28a3.24,3.24,0,0,1,0,4.59L87.23,65.69a3.25,3.25,0,0,1-5.57-2.3V56.9H33A3.24,3.24,0,0,1,29.78,53.66Zm66,20.53H47.07V67.72a3.25,3.25,0,0,0-5.54-2.29L30.73,76.24a3.24,3.24,0,0,0,0,4.59L41.54,91.65a3.25,3.25,0,0,0,5.54-2.3V82.84H95.75A3.25,3.25,0,0,0,99,79.59V77.45a3.26,3.26,0,0,0-3.24-3.26Z"/>
        <g id="Ellipse_1117" data-name="Ellipse 1117">
            <path className="cls-1"
                  d="M64.28,127.46a63.19,63.19,0,1,1,63.19-63.18A63.25,63.25,0,0,1,64.28,127.46Zm0-116.39a53.21,53.21,0,1,0,53.21,53.21A53.27,53.27,0,0,0,64.28,11.07Z"/>
        </g>
    </g>
</svg>
`];

import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const setCore = (state, action) => update(
    state, {
        core: { $merge: action.payload }
    }
);

const reducers = {
    [actionTypes.core.setCore]: setCore,
};

const initialState = {
    axis: [
        
        {
            value: "mobility",
            label: "axis.mobility",
            status: "enabled",
            indicators: [
                "distance_travelled",
                "mode_share",
                "period_of_travel",
                "transfers",
                "travel_time"
            ],
            inactiveIndicators: [
                 "accessibility",
            ],
            defaultIndicator: "distance_travelled",

        },
        {
            value: "experience",
            label: "axis.experience",
            status: "enabled",
            indicators: [
                "condition_of_pt_vehicles",
                "crime_on_pt",
                "driver_behaviour",
                "public_transit_comfort",
                "public_transit_reliability",
                "sexual_harassment",
              
            ],
            inactiveIndicators: [
                "affordability",
                "traffic_fatalities",
            ],
            defaultIndicator: "crime_on_pt",

        },
        {
            value: "efficiency",
            label: "axis.efficiency",
            status: "enabled",
            indicators: [
                "vehicle_occupancy",              
            ],
            inactiveIndicators: [
                "co2_emissions",
                "congestion",
            ],
            defaultIndicator: "vehicle_occupancy",

        },
    ],
};

export default fromMap(reducers, initialState);

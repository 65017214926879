import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import App from "./App";

import { icons } from "./assets/icons";
import rootReducer from "./store/reducers";
import intialiseSagaMiddleware from "./store/sagas";

const composeEnhancers =
    process.env.NODE_ENV === "development"
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

intialiseSagaMiddleware(sagaMiddleware);

React.icons = icons;

window.__RENDER__MOBILITY__OBSERVATORY = function (targetDivId) {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById(targetDivId)
    );
};

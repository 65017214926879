export function getParamFromString(string_, param, separator = "?", paramSeparator = "&") {
    const string = string_.split(separator);
    if (string.length < 2) {
        return null;
    }

    const parts = string[1].split(paramSeparator);
    const paramParts = parts.map(p => p.split("="));

    const findParam = paramParts.find(e => e[0] === param);

    if (! findParam) {
        return null;
    }
    return findParam[1];
}

export function storeParam(key, value) {
    window.localStorage.setItem(key, value);
}

export function getParam(key) {
    return window.localStorage.getItem(key);
}

export function setParamFromString(string_, param_) {
    const value = getParamFromString(string_, param_);
    if (! value) {
        return null;
    }

    storeParam(param_, value);

    return value;
}

import {backArrow} from "./back-arrow";
import { greenMobility } from "./greenMobility";
import { efficiency } from "./efficiency";
import { universalAccess } from "./universalAccess";
import { chartArea } from "./chartArea";
import { dropdownChange } from "./dropdownChange";
import { experience } from "./experience";
import { mobility } from "./mobility";
import { dataStatusInsufficient  } from "./dataStatusInsufficient";


const icons = {
    greenMobility,
    efficiency,
    experience,
    mobility,
    universalAccess,
    chartArea,
    backArrow,
    dropdownChange,
    dataStatusInsufficient,
};

export default icons;

export const efficiency = ["60 60", `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.452 31.09">
        <defs>
            <style>.a{fill:#242121;}</style>
        </defs>
        <g transform="translate(-675 -824.503)">
            <path className="a" d="M-833.644,1220.917v30.1H-839.1v-30.1Z" transform="translate(1539.096 -395.429)"/>
            <path className="a" d="M-851.578,1236.81h5.451v19.609h-5.451Z" transform="translate(1543.334 -400.828)"/>
            <path className="a" d="M-864.313,1247.6h5.442v12.48h-5.442Z" transform="translate(1547.658 -404.489)"/>
            <path className="a"
                  d="M-859,1219.425l2.185,6.819-2.414-.608c-.281.6-.53,1.187-.831,1.752a13.98,13.98,0,0,1-7.322,6.472,21.873,21.873,0,0,1-9.411,1.5,1,1,0,0,1-.158-.048c1.169-.166,2.269-.286,3.354-.485a12.712,12.712,0,0,0,4.582-1.608,25.539,25.539,0,0,0,3.9-3.051,7.357,7.357,0,0,0,1.909-3.889c.095-.443.131-.9.213-1.344.04-.22-.052-.284-.247-.334-.566-.141-1.126-.3-1.737-.473Z"
                  transform="translate(1551.949 -394.922)"/>
            <path className="a" d="M-876.69,1253.985h5.464v8.263h-5.464Z" transform="translate(1551.862 -406.661)"/>
        </g>
    </svg>
`];

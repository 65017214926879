export const driverBehaviour = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.36 23.42">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <path id="Path_3662" data-name="Path 3662" class="cls-1" d="M12.27.16a10.28,10.28,0,0,1,1.41.5,2.07,2.07,0,0,1,1.21,1.6.37.37,0,0,0,.07.2,2.43,2.43,0,0,1,.7,1.41,6.51,6.51,0,0,1,.1,1.92.27.27,0,0,0,.11.28,1.08,1.08,0,0,1,.34.86,4,4,0,0,1-.85,2.77A6.85,6.85,0,0,0,14.62,11a4.56,4.56,0,0,1-2,2.29,3.44,3.44,0,0,1-4.1-.58A4.82,4.82,0,0,1,7.32,11a6,6,0,0,0-.73-1.27,4.06,4.06,0,0,1-.85-2.83A1,1,0,0,1,6.14,6a.27.27,0,0,0,.06-.19c0-.53,0-1.07,0-1.6A4.15,4.15,0,0,1,9.79.12l.28,0A5.65,5.65,0,0,1,12,.09Z"/>
    <path id="Path_3663" data-name="Path 3663" class="cls-1" d="M7.22,12.24c.36.37.68.74,1,1.06a4,4,0,0,0,3,1,4.38,4.38,0,0,0,2.8-1.41,6.86,6.86,0,0,0,.48-.59.16.16,0,0,1,.21-.06h0l1.62.65c-2.31,2.86-2.29,5.77-.26,8.76-.38,0-.77.08-1.16.1a54.21,54.21,0,0,1-8.06,0,25.78,25.78,0,0,1-5.38-.85c-.44-.13-.87-.3-1.31-.46A.35.35,0,0,1,0,20.11a8.1,8.1,0,0,1,1.76-5A4.68,4.68,0,0,1,3.53,13.8c1.21-.53,2.44-1,3.66-1.56Z"/>
    <path id="Path_3664" data-name="Path 3664" class="cls-1" d="M27.36,17.45a6,6,0,1,1-6-6,6,6,0,0,1,6,6Zm-6.52-2.61V13.63a.73.73,0,0,0-.72-.73.66.66,0,0,0-.24,0,4.76,4.76,0,0,0-3.15,5.45.71.71,0,0,0,.84.57.85.85,0,0,0,.24-.09l2.12-1.21a.29.29,0,0,0,.14-.25,1.22,1.22,0,0,1,.62-1.09.3.3,0,0,0,.16-.3c0-.39,0-.78,0-1.17Zm.46,7.35h.13l.43,0A4.69,4.69,0,0,0,24.52,21a.72.72,0,0,0,.09-1,.62.62,0,0,0-.22-.17l-2.08-1.2a.25.25,0,0,0-.3,0,1.25,1.25,0,0,1-1.27,0,.24.24,0,0,0-.28,0L18.4,19.8a.7.7,0,0,0-.34,1,.63.63,0,0,0,.23.28,4.79,4.79,0,0,0,3,1.14Zm.63-7.36v1.23a.24.24,0,0,0,.13.24,1.3,1.3,0,0,1,.65,1.13.21.21,0,0,0,.14.22l2.07,1.19a.72.72,0,0,0,1-.17.78.78,0,0,0,.13-.36,6.5,6.5,0,0,0,0-1.46A4.63,4.63,0,0,0,22.92,13a.72.72,0,0,0-.94.39.7.7,0,0,0-.05.32v1.17ZM21.39,18a.58.58,0,1,0-.59-.57A.58.58,0,0,0,21.39,18Z"/>
  </g>
</svg>`];


import React from "react";
// import LanguageContext from "../../../common/contexts/LanguageContext";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { getLocalToken } from "../../../../config/keycloak";
import {
    Observatory as ObservatoryModule,
    ConfigProvider,
    defaultConfig,
} from "@gometro/mobility-observatory-visualization-module/dist/index.js";

import actions from "../../../common/store/actions";
import obsActions from "../../store/actions";
import Observatory from "./Observatory";

function toIndicator(string) {
    return string.replaceAll("-", "_");
}

function toHistory(string) {
    return string.replaceAll("_", "-");
}

const ObservatoryHoc = ({
    updateState,
    stateIndicator,
    stateCity,
    stateZone,
    stateOption,
    obsProject,
    updateCityList,
    updateZoneList,
    stateAxis,
}) => {
    const { axis, city, zone, indicator, language } = useParams();
    const history = useHistory();

    React.useEffect(() => {
        const indicatorValue = toIndicator(indicator);
        updateState({
            axis: {
                value: axis,
                label: `axis.${axis}`,
            },
            city: {
                value: city === "all" ? city : city.toUpperCase(),
                label: `city.${city.toUpperCase()}`,
            },
            zone: {
                value: zone === "all" ? zone : zone.toUpperCase(),
                label: `zone.${zone}`,
            },
            indicator: {
                value: indicatorValue,
                label: `indicator.${indicatorValue}`,
            },
            language: {
                value: language,
                label: `language.${language}`
            },
            option: {
                value: "all",
                label: "option.all",
            },
        });
    }, [axis, city, zone, indicator, updateState, language]);

    function onIndicatorChange(nextIndicator) {
        history.push(`/${language}/${axis}/${city}/${zone}/${toHistory(nextIndicator)}`);
    }

    function onOptionChange(nextOption) {
        updateState({
            option: { value: nextOption, label: `option.${nextOption}` },
        });
    }

    const onFirstLoad = React.useCallback(
        (results) => {
            async function update(results) {
                return new Promise((resolve) => {
                    const cities = results.project.configuration.countries
                        .map((e) => e.cities)
                        .flat()
                        .map((e) => ({
                            value: e.value,
                            label: e.label,
                        }));
                    updateCityList([
                        {
                            value: "all",
                            label: "city.ALL",
                        },
                        ...cities,
                    ]);
                    const zones = results.project.configuration.countries
                        .map(
                        (country) => country.cities
                            .map(
                                (city) => city.zones
                                    .map(function(zone){
                                        zone.city = city.value;
                                        return zone;
                                    }).flat()
                            ).flat()
                        ).flat();
                    
                    updateZoneList([
                        {
                            value: "all",
                            label: "zone.ALL",
                        },
                        ...zones
                    ])
                    resolve();
                });
            }

            update(results).then((r) => {});
        },
        [updateCityList, updateZoneList]
    );

    return (
        <ConfigProvider config={defaultConfig}>
            <ObservatoryModule
                indicator={stateIndicator.value}
                option={stateOption.value}
                city={stateCity.value}
                zone={stateZone.value}
                token={getLocalToken()}
                language={language}
                project={obsProject}
                hookOnFirstLoad={onFirstLoad}
                apiDomain={process.env.REACT_APP_OBSERVATORY_API}
                graphqlPrefix={"/observatory/v2"}
            >
                <Observatory
                    option={stateOption}
                    indicator={stateIndicator}
                    city={stateCity}
                    zone={stateZone}
                    onOptionChange={onOptionChange}
                    onIndicatorChange={onIndicatorChange}
                    axis={stateAxis}
                />
            </ObservatoryModule>
        </ConfigProvider>
    );
};

const mapStateToProps = (state) => ({
    stateIndicator: state.common.app.state.indicator,
    stateCity: state.common.app.state.city,
    stateZone: state.common.app.state.zone,
    stateOption: state.common.app.state.option || {
        value: "all",
        label: "option.all",
    },
    stateAxis: state.common.app.state.axis,
    obsProject: state.observatory.core.project || "aumo",
});
const mapDispatchToProps = (dispatch) => ({
    updateState: (state) => dispatch(actions.app.updateState(state)),
    updateCityList: (cities) =>
        dispatch(obsActions.listActions.updateList("cities", cities)),
    updateZoneList: (zones) =>
        dispatch(obsActions.listActions.updateList("zones", zones)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObservatoryHoc);

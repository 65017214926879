export const publicTransportComfort = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.36 22.98">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g id="Group_3015" data-name="Group 3015">
    <path id="Path_3679" data-name="Path 3679" class="cls-1" d="M24.72,23l-1.8-2.64c-.34-.5-.7-1-1-1.51a.37.37,0,0,1-.06-.29c.41-1.05.83-2.11,1.25-3.16,0,0,0,0,.07-.05l4.21,6-.27.18-2,1.25-.21.18Z"/>
    <path id="Path_3680" data-name="Path 3680" class="cls-1" d="M14,22.8h-4a.42.42,0,0,1-.43-.25Q4.79,13.49.1,4.41a.7.7,0,0,1,.15-.9.82.82,0,0,1,1,.24Q4.94,9,8.65,14.21a.52.52,0,0,0,.62.25q5.46-1.16,10.94-2.27a2.69,2.69,0,0,1,1,0,.88.88,0,0,1,.76,1,3.43,3.43,0,0,1-.21,1C20.7,17,19.59,19.7,18.51,22.46a.48.48,0,0,1-.53.35C16.64,22.79,15.29,22.8,14,22.8Z"/>
    <path id="Path_3681" data-name="Path 3681" class="cls-1" d="M18.58,8c.2.89.39,1.76.58,2.67l-1.73.4L10,12.72a.36.36,0,0,1-.44-.16c-.7-1-1.43-2.06-2.14-3.09a2.68,2.68,0,0,1,4.37-3.09c.6.82,1.21,1.63,1.8,2.46A.34.34,0,0,0,14,9l4.32-1Z"/>
    <path id="Path_3682" data-name="Path 3682" class="cls-1" d="M3.23,2.48A2.5,2.5,0,1,1,5.71,5h0A2.5,2.5,0,0,1,3.23,2.48Z"/>
  </g>
</svg>`];


export const affordability = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.35 19.15">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Icon_awesome-money-bill-wave" data-name="Icon awesome-money-bill-wave" class="cls-1" d="M26.55,1a12.69,12.69,0,0,0-5-1C16.31,0,11.05,2.66,5.78,2.66a12.66,12.66,0,0,1-4-.58A1.31,1.31,0,0,0,1.38,2,1.36,1.36,0,0,0,0,3.35V16.93a1.36,1.36,0,0,0,.81,1.26,12.61,12.61,0,0,0,5,1c5.26,0,10.53-2.67,15.79-2.67a12.93,12.93,0,0,1,4,.59,1.76,1.76,0,0,0,.45.07,1.35,1.35,0,0,0,1.37-1.34V2.22A1.36,1.36,0,0,0,26.55,1ZM2.05,4.28a15.2,15.2,0,0,0,2.68.39,2.75,2.75,0,0,1-2.68,2.2Zm0,12.18v-2a2.73,2.73,0,0,1,2.72,2.63A10.18,10.18,0,0,1,2.05,16.46Zm11.63-2.78c-1.89,0-3.42-1.84-3.42-4.1s1.53-4.1,3.42-4.1,3.42,1.83,3.42,4.1-1.54,4.1-3.42,4.1ZM25.3,14.86A15.13,15.13,0,0,0,23,14.5a2.71,2.71,0,0,1,2.32-2.1Zm0-10.09a2.72,2.72,0,0,1-2.38-2.63,10.8,10.8,0,0,1,2.38.54Z"/>
</svg>`];

import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const updateState = (state, action) => update(
    state, {
        state: { $merge: action.state },
    },
);

const reducers = {
    [actionTypes.app.updateState]: updateState,
};

const initialState = {
    state: {
        indicator: {
            value: "",
            label: "",
        },
        axis: {
            value: "",
            label: "",
        },
        city: {
            value: "all",
            label: "city.ALL",
        },
        zone: {
            value: "all",
            label: "zone.ALL"
        },
        language: {
            value: "en-gb",
            label: "language.en-gb",
        },
    }
    ,
};

export default fromMap(reducers, initialState);


import React, { useEffect } from "react";

import LanguageContext from "../../../common/contexts/LanguageContext";
import { getLegend } from "./Legend";
import { icons } from "../../../../assets/icons/index";

import {
    Utilities,
    ObservatoryContext,
    VisualisationIndicatorList,
    Mapbox,
    formatResultsForMapMarkers,
    formatResultsForMapPolygons,
    Patterns as Pattern
} from "@gometro/mobility-observatory-visualization-module/dist/index.js";

function formatDataStatusMessage(results, data, translate, filter) {
    // apply selection filter
    let filteredResults = filter ? results.filter((e) => e.split === filter) : results;
    // if no data to display after filter is applied then don't
    if(data.length === 0 || filteredResults.length === 0) {
        return {
            message: translate("info.data_status.insufficient"),
            image: {
                src: "data:image/svg+xml;base64," + btoa(icons.dataStatusInsufficient[1]),
                width: "60px",
                height: "60px"
            }
        };
    }
    return null;
}

function toRGBA(alpha, defaultColor, hexColor) {
    let color = hexColor;
    if(!hexColor || hexColor.length !== 7) { color = defaultColor; }
    let red = parseInt(color.substring(1,3), 16);
    let green = parseInt(color.substring(3,5), 16);
    let blue = parseInt(color.substring(5,7), 16);
    return "rgba(" + [red, green, blue, alpha].join(", ") + ")"; 
}
const applyColorAlpha = (color) => toRGBA(0.7, "#2BC4FF", color);

const onGeometryClick = (titleFn, { translate, legend, indicator }, { item, city, zone }) => {

    // get the selected indicator item
    let maxIndicatorValue = Math.max(0, ...item.indicator.map((e) => e.value).filter((e) => e));
    let maxIndicatorLabel = item.indicator.filter((e) => e.value === maxIndicatorValue)[0];
    let measurementLabel = translate(maxIndicatorLabel ? maxIndicatorLabel.label : null);
    let measurementType = translate("indicator." + indicator + ".measurement").trim();

    let sumOfIndicatorValues = item.indicator.reduce((acc, e) => e.value ? e.value + acc : acc, 0);

    // if the translation was unsuccessful and this label name ends with ".value"
    // then try the translation without the suffix
    if(maxIndicatorLabel && measurementLabel === maxIndicatorLabel.label && measurementLabel.endsWith(".value")) {
        measurementLabel = translate(measurementLabel.replace(/.value/g, ""));
    }

    let title = titleFn({ translate, legend, indicator }, { item, city, zone });
    let content = null;

    let dataInsufficient = translate("map.data-insufficient");
    if(item.dataStatus === "insufficient") {
        content = dataInsufficient;
    }
    else if(item.indicator.length === 1) {
        content = `
            <span>
                <nobr>
                    ${(measurementLabel && measurementLabel.length > 0 ? `${measurementLabel}: ` : "")} ${maxIndicatorValue} ${measurementType}
                </nobr>
            </span>
        `;
    } else {
        content = legend.map((legendItem) => {
            let label = legendItem.label;
            if(label === dataInsufficient) { return null; }
            let foundIndicator = item.indicator.filter((e) => e.label === label)[0];
            let value = foundIndicator && foundIndicator.value ? foundIndicator.value : 0;
            if(measurementType === "%" && sumOfIndicatorValues !== 0) {
                value = Math.round((value * 10000) / sumOfIndicatorValues) / 100;
            }
            return `
                <span>
                    <nobr>
                        ${(label && label.length > 0 ? `${label}: ` : "")} ${value}${measurementType === "%" ? "%" : ` ${measurementType}`}
                    </nobr>
                </span>
            `;
        }).filter((e) => e).join(" ");
    }

    // return the popup html
    return `
        <div class="c-observatory-map-popup">
            <h2 class="c-observatory-map-popup__title">${title}</h2>
            <div class="c-observatory-map-popup__content">${content}</div>
        </div>
    `;
};

const onCityClick = onGeometryClick.bind(null, ({ translate }, { city }) => {
    return translate(city.label);
});

const onZoneClick = onGeometryClick.bind(null, ({ translate }, { item }) => {
    return translate("zone." + item.split);
});

// apply customizations on rendering
const onZoneRender = (item, properties) => {
    if(!item) { return; }
    if(item.dataStatus === "insufficient") {
        properties.pattern = "striped";
    }
};

function translateLists(translate) {
    return {
        translate(string) {
            return translate(string);
        },
        translateLabel(current) {
            return {
                ...current,
                label: translate(current.label),
            };
        },
        results(current) {
            return {
                ...current,
                indicator: current.indicator.map((e) => ({
                    ...e,
                    label: translate(e.label),
                })),
            };
        },
    };
}

const debouncer = Utilities.debounce(300);

const ObservatoryMap = ({
    indicator = "travel_time",
    onIndicatorChange,
    onOptionChange,
    option,
    enabledIndicatorList = [],
    disabledIndicatorList = [],
    city,
    zone
}) => {
    const context = React.useContext(ObservatoryContext);
    const { translate } = React.useContext(LanguageContext);
    const translateList = translateLists(translate);
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const customStyle = process.env.REACT_APP_MAPBOX_STYLE;

    const [state, setState] = React.useState({
        indicators: context.getIndicators(
            {
                active: indicator,
                enabled: enabledIndicatorList,
                disabled: disabledIndicatorList,
                excludeOther: true,
            },
            translateList.translateLabel
        ),
        results: context.getResults(translateList.results),
        map: {
            type: "none",
            data: [],
            legend: []
        }
    });

    const untilDataLoaded = React.useCallback(
        e => new Promise((resolve, reject) => {
            debouncer(() => {
                resolve();
            });
        }),
        []
    );

    // recalculate
    useEffect(() => {
        // @todo refactor code
        async function fetchAndFormatMapData() {
            
            // when viewing the map, only the "all" option is allowed
            if(option.value !== "all") {
                return onOptionChange("all");
            }

            let translateList = translateLists(translate);
            let results = context.getResults(translateList.results);
            let legend = await getLegend(indicator, results, translateList.translateLabel);
            
            // if data insufficient detected
            // then include it in the legend
            if(results.filter((e) => e.dataStatus === "insufficient").length > 0) {
                let mustInclude = true;
                // if a single zone is selected then first check if the filtered results 
                if(zone.value !== "all") {
                    mustInclude = results.filter((e) => e.split === zone.value.toLowerCase() && e.dataStatus === "insufficient").length > 0;
                }
                if(mustInclude) {
                    let pattern = await Pattern.getPatternAsUrl(Pattern.Patterns.striped.id);
                    legend = [translateList.translateLabel({
                        label: "map.data-insufficient",
                        image: pattern
                    }), ...legend];
                }
            }

            let mapType = null;
            let fetchAndFormatFunction = null;

            // city view / markers
            if(city.value === "all") {
                let onClick = onCityClick.bind(null, {
                    translate: translateList.translate,
                    legend,
                    indicator
                });
                let cities = context.getCities();
                fetchAndFormatFunction = formatResultsForMapMarkers.bind(null, results, legend, cities, onClick, applyColorAlpha);
                mapType = "markers";
            }
            // zones(s) view / polygons
            else {
                let onClick = onZoneClick.bind(null, {
                    translate: translateList.translate,
                    legend,
                    indicator
                });
                let baseApiURL = context.getDomain();
                let zones = context.getZonesForCity(city.value);
                fetchAndFormatFunction = formatResultsForMapPolygons.bind(null, baseApiURL, results, legend, zones, zone, onZoneRender, onClick, applyColorAlpha);
                mapType = "polygons";
            }

            // fetch, format and display the data
            let formattedData = await fetchAndFormatFunction();

            setState({
                indicators: context.getIndicators(
                    {
                        active: indicator,
                        enabled: enabledIndicatorList,
                        disabled: disabledIndicatorList,
                        excludeOther: true,
                    },
                    translateList.translateLabel
                ),
                results: context.getResults(translateList.results),
                map: {
                    type: mapType,
                    data: formattedData,
                    legend: legend
                }
            });
        }

        // debounce
        untilDataLoaded()
            .then(()=>{
                fetchAndFormatMapData();
            })
            .catch(e => console.warn(e));

    }, [untilDataLoaded, indicator, option, onOptionChange, city, zone, context, translate, enabledIndicatorList, disabledIndicatorList]);

    return (
        <div>
            <div className="c-observatory-graph">
                <div className="c-observatory-graph__content">
                    <div className="observatory-c-visualisation c-observatory-map-indicators">
                        <div className="observatory-c-visualisation__indicators">
                            <VisualisationIndicatorList
                                indicators={state.indicators}
                                onClick={onIndicatorChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Mapbox 
                accessToken={accessToken}
                mapData={state.map}
                style={customStyle}
                messageOverlay={formatDataStatusMessage(state.results, state.map.data, translate, zone.value !== "all" ? zone.value.toLowerCase() : null)}
            />
        </div>
    );
};
export default ObservatoryMap;

const exports = {
    authentication: {
        authenticate: "common.authentication.authenticate",
        authenticating: "common.authentication.authenticating",
        authenticationSucceeded: "common.authentication.authenticationSucceeded",
        authenticationFailed: "common.authentication.authenticationFailed",
        completeAuthentication: "common.authentication.completeAuthentication",
        completingAuthentication: "common.authentication.completingAuthentication",
        initialise: "common.authentication.initialise",
        initialising: "common.authentication.initialising",
        initialisationSucceeded: "common.authentication.initialisationSucceeded",
        initialisationFailed: "common.authentication.initialisationFailed",
        logout: "common.authentication.logout",
        loggingOut: "common.authentication.loggingOut",
        logoutSucceeded: "common.authentication.logoutSucceeded",
        refreshToken: "common.authentication.refreshToken"
    },
    layout: {
        asideOpened: "common.layout.asideOpened",
        asideClosed: "common.layout.asideClosed",
        darkModeToggled: "common.layout.darkModeToggled",
        mobileSidebarToggled: "common.layout.mobileSidebarToggled",
        sidebarToggled: "common.layout.sidebarToggled"
    },
    app: {
        updateState: "common.app.updateState"
    }
};
export default exports;

import React from "react";
import { CIcon } from "@coreui/icons-react";

const TextBlock = ({ svg, title, sections }) => {
    return (
        <div className="c-text-block">
            <h3 className="c-text-block__title">
                <span className="c-text-block__title__svg">
                    <CIcon
                        name={svg}
                        size="xl"
                        className="under-construction-icon"
                    />
                </span>
                <span className="c-text-block__title__text">{title}</span>
            </h3>
            {sections.map((e, key) => (
                <p className={`c-text-block__content`} key={`${key}`}>{e}</p>
            ))}
        </div>
    );
};

export default TextBlock;

import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const updateState = (state, action) => update(
    state, {
        state: { $merge: action.state },
    },
);

const reducers = {
    [actionTypes.app.updateState]: updateState,
};

const initialState = {
    state: {
        city: "",
        zone: "",
        option: "",
        indicator: "",
        axis: "",
    },
};

export default fromMap(reducers, initialState);

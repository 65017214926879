function replace(string, ...replacements) {
    let result = string;

    for (let i = 0; i < replacements.length; i++) {
        result = result.replace(/:(\w+)/, replacements[i]);
    }

    return result;
}

export default function translate(translationList) {
    return (translationKey, ...replacements) => {
        try {
            let key = translationKey;
            if (translationKey.includes('.')) {
                key = translationKey.split('.').reduce((a, c) => a[c], translationList);
                if (typeof key === 'object') {
                    key = key[0];
                }
            } else {
                key = translationList[translationKey];
            }

            const result = (
                replace(key, ...replacements) ||
                translationKey
            );
            return result;
        } catch (e) {
            return translationKey;
        }
    };
}

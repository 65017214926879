const exports = {
    app: {
        setState: "observatory.app.setState",
    },
    visualizationType: {
        setMap: "observatory.layout.setMap",
        setGraph: "observatory.layout.setGraph"
    },
    core: {
        updateCore: "observatory.core.updateCore",
    },
    list: {
        updateList: "observatory.list.updateList"
    },
}

export default exports;

import React from "react";
import {CIcon} from "@coreui/icons-react";

function formatContentAsNumber(string, run) {
    if (! run || ! string) return string;

    const arr = `${string}`.split("").reverse();
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        result.push(arr[i]);

        if ((i + 1) % 3 === 0) {
            result.push(" ");
        }
    }

    return result.reverse().join("");
}

const StackedInfo = ({ icon, mainContent, subContent, mainContentIsNumber = true }) => {
    return (
        <div className={"c-stacked-info"}>
            <div className="c-stacked-info__icon">
                <CIcon
                    name={icon}
                    size="2xl"
                    className="under-construction-icon"
                />
            </div>
            <div className="c-stacked-info__content">
                <div className="c-stacked-info__content__main">
                    {formatContentAsNumber(mainContent, mainContentIsNumber)}
                </div>
                <div className="c-stacked-info__content__sub">
                    {subContent}
                </div>
            </div>
        </div>
    );
};

export default StackedInfo;

export const access = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.35 23.35">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Icon_awesome-arrow-alt-circle-up" data-name="Icon awesome-arrow-alt-circle-up" class="cls-1" d="M0,11.68A11.68,11.68,0,1,1,11.68,23.35h0A11.67,11.67,0,0,1,0,11.68Zm13.75,5.46V11.68h3.34a.56.56,0,0,0,.56-.57.59.59,0,0,0-.16-.4L12.08,5.33a.58.58,0,0,0-.8,0h0L5.87,10.71a.56.56,0,0,0,0,.8.54.54,0,0,0,.4.17H9.6v5.46a.57.57,0,0,0,.57.56h3A.57.57,0,0,0,13.75,17.14Z"/>
</svg>
`]

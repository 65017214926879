export const sexualHarassment = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.35 25.35">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Path_3691" data-name="Path 3691" class="cls-1" d="M12.68,0A12.68,12.68,0,1,0,25.35,12.68h0A12.67,12.67,0,0,0,12.68,0Zm8.91,15.05H3.84c-.59,0-.66-.07-.67-.65,0-1.19,0-2.39,0-3.58,0-.42.13-.52.56-.52H21.55c.54,0,.63.1.63.65v3.51c0,.48-.1.59-.59.59Z"/>
</svg>`];


import { combineReducers } from "redux";

import coreReducer from "./core";
import listReducer from "./lists";
import appReducer from "./app";
import visualizationTypeReducer from "./visualizationType";

const observatoryReducer = combineReducers({
    core: coreReducer,
    app: appReducer,
    list: listReducer,
    visualizationType: visualizationTypeReducer
});

export default observatoryReducer;

import { combineReducers } from "redux"

import authenticationReducer from "./authentication";
import layoutReducer from "./layout";
import appReducer from "./app";

const commonReducer = combineReducers({
    authentication: authenticationReducer,
    layout: layoutReducer,
    app: appReducer,
});

export default commonReducer;

export const dataStatusInsufficient = ["104.343 104.343", `
    <svg xmlns="http://www.w3.org/2000/svg" width="104.343" height="104.323" viewBox="0 0 104.343 104.323">
        <defs>
            <style>.a{fill:#fff;stroke:#000;}</style>
        </defs>
        <g transform="translate(-975.468 -601.498)">
            <g transform="translate(976 602)">
                <g transform="translate(0)">
                <path class="a" d="M261.116,586.71l4.8,4.577c3.728-2.635,3.729-2.635,7.044.677,5.452,5.445,10.925,10.871,16.35,16.345,4.977,5.022,5.229,11.651.68,16.181-4.564,4.546-11.172,4.3-16.188-.667-5.622-5.567-11.2-11.18-16.789-16.777-3.083-3.086-3.079-3.089-.291-6.314l-4.974-4.886c-10.407,6.99-22.157,9.107-34.708,5.118A37.959,37.959,0,0,1,195.9,584.409a39.219,39.219,0,0,1,57.772-51.377C267.871,544.324,274.479,566.5,261.116,586.71Zm-31.844-58.131a35.1,35.1,0,0,0-35.18,34.689c-.194,19.274,15.292,35.285,34.366,35.532,19.56.253,35.769-15.449,35.845-34.721A35.158,35.158,0,0,0,229.272,528.579Z" transform="translate(-189.921 -524.412)"/>
                <path class="a" d="M246.217,543.222c6.13-.018,5.321-.338,5.039,5.155-.322,6.267-.771,12.528-1.083,18.8-.084,1.68-.688,2.389-2.434,2.336-5.99-.182-5.461,1.046-5.812-5.463q-.478-8.872-1.049-17.738c-.177-2.786.044-3.07,2.8-3.082C244.525,543.221,245.371,543.224,246.217,543.222Z" transform="translate(-206.888 -522.908)"/>
                <path class="a" d="M243.1,616.169c0-4.352,0-4.326,4.378-4.344,4.589-.02,4.341-.2,4.316,4.406-.024,4.352.011,4.352-4.379,4.359C243.1,620.6,243.1,620.6,243.1,616.169Z" transform="translate(-208.193 -562.366)"/>
                </g>
            </g>
        </g>
    </svg>
`];


// import TravelTime from "./pages/travel-time"
// import ComingSoon from "./pages/ComingSoon"
// import SelectIndicator from "./pages/SelectIndicator"
// const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"))
import Observatory from "./pages/Observatory";
import StartObservatory from "./pages/StartObservatory";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: "/:language/:axis/:city/:zone/:indicator", name: "Observatory", component: Observatory },
    { path: "/:language/:axis/:city/:zone", name: "Observatory", component: Observatory },
    { path: "/:language/:axis/:city", name: "Observatory", component: Observatory },
    { path: "/:language/:axis", name: "StartObservatory", component: StartObservatory },
    { path: "/:language/start", name: "StartObservatory", component: StartObservatory },
    { path: "/start", name: "StartObservatory", component: StartObservatory },
]

export default routes

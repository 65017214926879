import React, { memo } from "react";
import { connect } from "react-redux";
import {
    CContainer,
    CCol,
    CRow
} from "@coreui/react";
import LanguageContext from "../../../common/contexts/LanguageContext";
import LinkTextBlock from "../../components/LinkTextBlock";

const StartObservatory = ( axisList ) => {
    const { translate } = React.useContext(LanguageContext);

    return (
        <div className={"p-start-observatory"}>
            <div>
                <h2>{translate("landing.title")}</h2>
            </div>
            <div>
                <CContainer fluid>
                    <CRow>
                        <CCol xs="3"></CCol>
                        {axisList.axisList.map( (axis) => (
                            <CCol xs="2">
                                <LinkTextBlock
                                    svg={axis.value}
                                    axis={axis.value}
                                    activeIndicators={axis.indicators}
                                    disabledIndicators={axis.inactiveIndicators}
                                />
                            </CCol>
                        ))}
                    </CRow>
                </CContainer>
            </div>            
        </div>
    );
}

const mapStateToProps = (state) => ({
    axisList: state.observatory.core.axis,
});

export default connect(mapStateToProps)(memo(StartObservatory));

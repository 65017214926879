export const chartArea = ["60 60", `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.661 28.996">
        <defs>
            <style>.a{fill:#242121;}</style>
        </defs>
        <path className="a"
              d="M37.755,28.663a.909.909,0,0,1,.906.906v3.02a.909.909,0,0,1-.906.906H.906A.909.909,0,0,1,0,32.59V5.406A.909.909,0,0,1,.906,4.5h3.02a.909.909,0,0,1,.906.906V28.663ZM28.143,11.711l-6.4,4.266L15.306,7.392a.908.908,0,0,0-1.5.076L7.249,18.394v7.853h29L29.457,12.066a.906.906,0,0,0-1.314-.355Z"
              transform="translate(0 -4.5)"/>
    </svg>
`]

import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const asideOpened = (state, action) =>
    update(state, {
        showAside: { $set: true },
    });

const asideClosed = (state, action) =>
    update(state, {
        showAside: { $set: false },
    });

const darkModeToggled = (state, action) =>
    update(state, {
        darkMode: { $set: !state.darkMode },
    });


const mobileSidebarToggled = (state, action) => {
    const showSidebar = [ false, "responsive" ].includes(state.showSidebar) ? true : "responsive"

    return update(state, {
        showSidebar: { $set: showSidebar }
    })
}

const sidebarToggled = (state, action) => {
    const showSidebar = [ true, "responsive" ].includes(state.showSidebar) ? false : "responsive"

    return update(state, {
        showSidebar: { $set: showSidebar }
    })
}

const reducers = {
    [actionTypes.layout.asideOpened]: asideOpened,
    [actionTypes.layout.asideClosed]: asideClosed,
    [actionTypes.layout.darkModeToggled]: darkModeToggled,
    [actionTypes.layout.mobileSidebarToggled]: mobileSidebarToggled,
    [actionTypes.layout.sidebarToggled]: sidebarToggled,
}

const initialState = {
    darkMode: false,
    showAside: false,
    showSidebar: false
};

export default fromMap(reducers, initialState);

export const conditionOfPtVehicles = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.36 23.81">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g id="Group_3011" data-name="Group 3011">
    <path id="Path_3654" data-name="Path 3654" class="cls-1" d="M23.58,13.57c.17.47.37.93.5,1.39a5.51,5.51,0,0,1-1.52,5.91,2.16,2.16,0,0,1-.59.39.27.27,0,0,0-.19.3c0,.41,0,.81,0,1.22a1,1,0,0,1-.92,1h0c-.48,0-1,0-1.45,0a1,1,0,0,1-.94-1h0c0-.37,0-.73,0-1.1,0-.22-.06-.27-.27-.27H6.72v1.3a1,1,0,0,1-.9,1.08H4.44a1,1,0,0,1-1-.95v-.07c0-.42,0-.84,0-1.25a.29.29,0,0,0-.1-.22,4.45,4.45,0,0,1-2.24-3,6.18,6.18,0,0,1,.48-4.12l.24-.53v-.06l-.54-.06A1.28,1.28,0,0,1,0,12.22s0-.09,0-.14a2.8,2.8,0,0,1,.13-.64A.71.71,0,0,1,1,10.93l1.57.16a.26.26,0,0,0,.3-.18h0C3.47,9.44,4.09,8,4.7,6.51A2.41,2.41,0,0,1,6.94,5c3.78,0,7.56,0,11.33,0a2.35,2.35,0,0,1,2.27,1.53l1.86,4.39a.25.25,0,0,0,.3.18h0l1.68-.19a.68.68,0,0,1,.79.45,1.86,1.86,0,0,1,.11,1.25,1.35,1.35,0,0,1-1.16.91A4.68,4.68,0,0,1,23.58,13.57Zm-19.2-2H20.86a1.32,1.32,0,0,1-.08-.19C20.19,10,19.6,8.6,19,7.21a.84.84,0,0,0-.87-.57H7.09a.86.86,0,0,0-.85.56c-.41,1-.85,2-1.27,3Zm16.7,4.34a1.46,1.46,0,1,0-1.48,1.44h0a1.44,1.44,0,0,0,1.43-1.44ZM5.49,14.41a1.46,1.46,0,1,0,0,2.92h0a1.47,1.47,0,0,0,1.49-1.45h0a1.44,1.44,0,0,0-1.42-1.46Z"/>
    <path id="Path_3655" data-name="Path 3655" class="cls-1" d="M8.67,1.62l.85-.36A.58.58,0,0,0,9.74,1c.15-.32.28-.65.44-1,.16.36.28.68.44,1a.72.72,0,0,0,.3.3c.24.13.5.21.79.34-.33.14-.62.26-.9.4a.59.59,0,0,0-.23.24c-.13.28-.24.57-.38.91-.13-.33-.24-.62-.37-.9A.67.67,0,0,0,9.58,2C9.3,1.88,9,1.76,8.67,1.62Z"/>
    <path id="Path_3656" data-name="Path 3656" class="cls-1" d="M22.31,2c.15.37.28.7.43,1A.59.59,0,0,0,23,3.3c.27.13.54.23.86.36a8.72,8.72,0,0,0-.86.4.59.59,0,0,0-.27.27c-.13.27-.24.55-.38.9A8.19,8.19,0,0,0,22,4.36a.69.69,0,0,0-.3-.31c-.26-.14-.55-.24-.87-.38a6.44,6.44,0,0,1,.61-.24A.74.74,0,0,0,22,2.86C22.07,2.59,22.19,2.34,22.31,2Z"/>
    <path id="Path_3657" data-name="Path 3657" class="cls-1" d="M1.2,3.61a5.12,5.12,0,0,1,.65-.25.71.71,0,0,0,.54-.54c.08-.26.2-.52.31-.83.16.36.29.69.45,1a.64.64,0,0,0,.27.27c.24.12.51.22.82.35-.32.14-.6.25-.86.39a.71.71,0,0,0-.28.29c-.12.27-.21.55-.34.88-.14-.31-.27-.57-.37-.85A.53.53,0,0,0,2,4Z"/>
    <path id="Path_3658" data-name="Path 3658" class="cls-1" d="M16,2.68a.75.75,0,1,1,.76-.75h0A.76.76,0,0,1,16,2.68Z"/>
    <path id="Path_3659" data-name="Path 3659" class="cls-1" d="M25.36,7.81a.75.75,0,0,1-1.5,0v0a.75.75,0,1,1,1.5,0Z"/>
    <path id="Path_3660" data-name="Path 3660" class="cls-1" d="M1.2,9a.76.76,0,0,1-.77-.74.77.77,0,0,1,.75-.77.76.76,0,0,1,.76.74A.73.73,0,0,1,1.22,9Z"/>
  </g>
</svg>`];

export const publicTrReliability = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.85 28.87">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <g id="Group_3023" data-name="Group 3023">
      <g id="Group_3022" data-name="Group 3022">
        <g id="Group_3021" data-name="Group 3021">
          <path id="Path_3688" data-name="Path 3688" class="cls-1" d="M22.16,10.44l.05.08a1.25,1.25,0,0,1-.16,1.75l-.13.09a.67.67,0,0,0-.28.52,3.45,3.45,0,0,1-.1.56,1.05,1.05,0,0,1-.88.86c-.49.08-1,.11-1.48.15a31.72,31.72,0,0,1-5.59-.25l-1.1-.13a.36.36,0,0,1-.37-.34v-.06c0-.4,0-.8,0-1.2V7.59c0-.3.05-.37.35-.46a1.12,1.12,0,0,0,.69-.72,5.85,5.85,0,0,1,1.29-1.83,15,15,0,0,1,1.36-1.17,2.54,2.54,0,0,0,1.09-1.55c.05-.31.07-.63.1-.94A.91.91,0,0,1,17.89,0H18a.8.8,0,0,1,.7.53,2.76,2.76,0,0,1,.13,2.3c-.26.63-.56,1.25-.83,1.88a2.59,2.59,0,0,0-.14.45.31.31,0,0,0,.22.37h.05a1.5,1.5,0,0,0,.37,0l1.78,0a4.46,4.46,0,0,1,1.54.22,1.32,1.32,0,0,1,.87,1.66l0,.1a4,4,0,0,1-.21.47.41.41,0,0,0,0,.46,3.45,3.45,0,0,1,.28.54.89.89,0,0,1-.17,1Z"/>
          <path id="Path_3689" data-name="Path 3689" class="cls-1" d="M9.05,10.65V7.56c0-.32.1-.42.42-.42h1.84c.3,0,.4.1.4.4v6.22c0,.3-.1.4-.4.4H9.45c-.3,0-.4-.1-.4-.41Z"/>
        </g>
      </g>
    </g>
    <path id="Path_3690" data-name="Path 3690" class="cls-1" d="M16.46,15.51c-.51,0-1,0-1.53-.07v5.11H1.52v-8H8.1V8.69a.44.44,0,0,1,.1-.33H4.13a3.94,3.94,0,0,0-1.4.25A4.14,4.14,0,0,0,0,12.51V24.73A1.42,1.42,0,0,0,1.05,26.1l.31.07v1.27a1.43,1.43,0,0,0,1.41,1.43h1.4a1.43,1.43,0,0,0,1.42-1.43V26.16h5.28v1.26a1.43,1.43,0,0,0,1.4,1.45h1.32a1.44,1.44,0,0,0,1.51-1.35V26.16a1.45,1.45,0,0,0,1.36-1.54h0ZM2.8,24.64a1.28,1.28,0,1,1,1.27-1.29h0A1.29,1.29,0,0,1,2.8,24.64Zm10.86,0a1.28,1.28,0,0,1,0-2.56,1.28,1.28,0,1,1,0,2.56Z"/>
  </g>
</svg>`];


import React from "react";
import { CIcon } from "@coreui/icons-react";
import { CNavLink } from '@coreui/react';
import LanguageContext from "../../../common/contexts/LanguageContext";


function indicatorToUrl(string) {
    return string.replaceAll("_", "-");
}

const LinkTextBlock = ({ svg, activeIndicators, disabledIndicators, axis }) => {
    const { translate } = React.useContext(LanguageContext);

    return (
        <div className="c-text-block">
            <h3 className="c-text-block__title">
                <span className="c-text-block__title__svg">
                    <CIcon
                        name={svg}
                        size="xl"
                        className="under-construction-icon"
                    />
                </span>
                <span className="c-text-block__title__text">{translate(`axis.${axis}`)}</span>
            </h3>
            {/* Add links to active indicators */}
            {activeIndicators.map((indicator, key) => (
                <CNavLink
                    to={`/en-gb/${axis}/all/all/${indicatorToUrl(indicator)}`}
                    className={`c-text-block__content__link`}
                    key={`${key}`}
                >
                    {translate(`indicator.${indicator}`)}
                </CNavLink>
            ))}
            {/* Grey out and disable inactive indicators */}
            {disabledIndicators.map((indicator, key) => (
                // CNavLink here for text positioning
                <CNavLink disabled={true} className={`c-text-block__content__link c-text-block__content__link--disabled`} key={`${key}`}>
                    {translate(`indicator.${indicator}`)}
                </CNavLink>
            ))}
        </div>
    );
};

export default LinkTextBlock;

export const travelTime = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.73 18.73">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }
    </style>
  </defs>
  <path id="Icon_ionic-md-timer" data-name="Icon ionic-md-timer" class="cls-1" d="M8.3,14.6a1,1,0,1,0,1-1h0A1.05,1.05,0,0,0,8.3,14.6ZM8.3,0V4.17h2.09v-2A7.3,7.3,0,1,1,2.05,9.39,7.19,7.19,0,0,1,3.69,4.77l5.66,5.66L10.82,9,3.72,1.87v0A9.37,9.37,0,1,0,9.35,0Zm7.3,9.39a1,1,0,1,0-2.08,0,1.05,1.05,0,0,0,1,1h0a1.06,1.06,0,0,0,1-1Zm-12.51,0a1,1,0,1,0,1-1h0a1,1,0,0,0-1,1Z"/>
</svg>`];


export const trafficFatalities = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.87 25">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <path id="Path_3692" data-name="Path 3692" class="cls-1" d="M10.93,21.88H21.45c.42,0,.42,0,.42.42v2.37c0,.29,0,.33-.32.33H.32C0,25,0,25,0,24.67V22.21c0-.3,0-.33.34-.33Z"/>
    <g id="Group_3024" data-name="Group 3024">
      <path id="Path_3693" data-name="Path 3693" class="cls-1" d="M18.74,7.82A7.8,7.8,0,0,0,12.53.17c-.34-.08-.67-.11-1-.17H10.35C9.8.11,9.24.18,8.71.33A7.73,7.73,0,0,0,3.17,7.09c-.06.63,0,1.27,0,1.9V21.08H18.75v-.23Q18.75,14.34,18.74,7.82ZM8,11.9c-.08,0-.18-.13-.23-.21-.31-.54-.63-1.08-.9-1.65a.55.55,0,0,0-.66-.37H5.86v2.22H4.74V6.13s0,0,0,0c.89,0,1.79,0,2.67.07A1.46,1.46,0,0,1,8.84,7.42,1.73,1.73,0,0,1,8,9.4l-.19.1,1.37,2.4c-.43,0-.8,0-1.16,0Zm3.54,0h-1.1V6.1h1.1ZM16.1,9.72a6.54,6.54,0,0,1-.89.09h-.77v2.07H13.32V6c1,.06,2,0,2.95.19A1.55,1.55,0,0,1,17.53,8,1.76,1.76,0,0,1,16.1,9.71Z"/>
      <path id="Path_3694" data-name="Path 3694" class="cls-1" d="M15.75,7.06C15.32,7,14.89,7,14.46,7V8.9a11.73,11.73,0,0,0,1.33-.11.83.83,0,0,0,.62-.95A.7.7,0,0,0,15.75,7.06Z"/>
      <path id="Path_3695" data-name="Path 3695" class="cls-1" d="M7.11,7.06C6.7,7,6.29,7,5.88,7V8.78c.42,0,.84-.06,1.27-.12s.62-.41.6-.9a.63.63,0,0,0-.57-.69Z"/>
    </g>
  </g>
</svg>`];


export const vehicleOccupancy = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.66 18.11">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <g id="Group_1875" data-name="Group 1875">
      <g id="Group_1874" data-name="Group 1874">
        <path id="Path_3067" data-name="Path 3067" class="cls-1" d="M5.83,14.12l-3.54-.27a1.45,1.45,0,0,1-1.24-.79,4.89,4.89,0,0,1-.59-2.21,13.31,13.31,0,0,1,.79-4.68c.16-.51.34-1,.52-1.52a1.34,1.34,0,0,1,1.41-1c.85,0,1.71,0,2.56,0H8.21c1,0,1.45.25,1.73,1.13.39,1.25.78,2.5,1.08,3.78A6.85,6.85,0,0,1,11,12.25a7.58,7.58,0,0,1-.36.84,1.41,1.41,0,0,1-1.22.76Z"/>
        <path id="Path_3068" data-name="Path 3068" class="cls-1" d="M5.82,18.09c-1.07,0-2.14,0-3.21,0a3.86,3.86,0,0,1-.76-.1A1.64,1.64,0,0,1,.53,16.93,12.57,12.57,0,0,1,0,15.21c-.17-.78.45-1.28,1.32-1.08a23.71,23.71,0,0,0,7.47.27,9.55,9.55,0,0,0,1.4-.25c1-.23,1.62.3,1.4,1.17-.1.42-.22.84-.35,1.25a1.94,1.94,0,0,1-2.1,1.51c-1.11.05-2.24,0-3.36,0Z"/>
        <path id="Path_3069" data-name="Path 3069" class="cls-1" d="M5.84,3.2H4.23c-.6,0-.82-.23-.72-.82s.27-1.2.42-1.8A.67.67,0,0,1,4.5.12a7.38,7.38,0,0,1,2.65,0A.73.73,0,0,1,7.77.7c.13.54.28,1.08.38,1.62s-.1.88-.76.88Z"/>
      </g>
    </g>
    <g id="Group_2994" data-name="Group 2994">
      <g id="Group_1874-2" data-name="Group 1874-2">
        <path id="Path_3067-2" data-name="Path 3067-2" class="cls-1" d="M17.83,14.12l-3.54-.27a1.45,1.45,0,0,1-1.24-.79,4.89,4.89,0,0,1-.59-2.21,13.31,13.31,0,0,1,.79-4.68c.16-.51.34-1,.52-1.52a1.34,1.34,0,0,1,1.41-1c.85,0,1.71,0,2.56,0h2.47c1,0,1.45.25,1.73,1.13.39,1.25.78,2.5,1.08,3.78A6.85,6.85,0,0,1,23,12.25a7.58,7.58,0,0,1-.36.84,1.41,1.41,0,0,1-1.22.76Z"/>
        <path id="Path_3068-2" data-name="Path 3068-2" class="cls-1" d="M17.82,18.09c-1.07,0-2.14,0-3.21,0a3.86,3.86,0,0,1-.76-.1,1.64,1.64,0,0,1-1.32-1.06,12.57,12.57,0,0,1-.5-1.72c-.17-.78.45-1.28,1.32-1.08a23.71,23.71,0,0,0,7.47.27,9.55,9.55,0,0,0,1.4-.25c1-.23,1.62.3,1.4,1.17-.1.42-.22.84-.35,1.25a1.94,1.94,0,0,1-2.1,1.51c-1.11.05-2.24,0-3.36,0Z"/>
        <path id="Path_3069-2" data-name="Path 3069-2" class="cls-1" d="M17.84,3.2H16.23c-.6,0-.82-.23-.72-.82s.27-1.2.42-1.8A.67.67,0,0,1,16.5.12a7.38,7.38,0,0,1,2.65,0,.73.73,0,0,1,.62.58c.13.54.28,1.08.38,1.62s-.1.88-.76.88Z"/>
      </g>
    </g>
  </g>
</svg>`];


export const universalAccess = ["60 60", `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.263 34.222">
        <defs>
            <style>.a{fill:#242121;}</style>
        </defs>
        <path className="a"
              d="M-811.889,500.416H-815.1c-.048-.057-.1-.089-.1-.12.018-1.691-.09-3.4.087-5.07a7.894,7.894,0,0,1,7.663-7c6.215-.07,12.432-.079,18.647,0a7.989,7.989,0,0,1,7.738,7.978q.027,9.067,0,18.134a8.04,8.04,0,0,1-7.91,8.016q-9.163.071-18.327,0a8.05,8.05,0,0,1-7.907-7.7c-.067-1.475-.011-2.955-.011-4.492h3.329c0,1.286-.042,2.568.017,3.845a6.081,6.081,0,0,0,.382,2.064,4.5,4.5,0,0,0,4.362,2.94q9,.057,18.005.006a4.716,4.716,0,0,0,4.722-4.711q.042-9.035,0-18.07a4.7,4.7,0,0,0-4.67-4.694q-9.066-.055-18.133,0a4.738,4.738,0,0,0-4.679,4.761C-811.909,497.654-811.889,499-811.889,500.416Z"
              transform="translate(819.316 -488.168)"/>
        <path className="a" d="M-792.09,511.2l-9.969,9.977v-6.35h-20.535v-7.446h20.485v-6.209Z"
              transform="translate(822.594 -493.964)"/>
    </svg>
`]

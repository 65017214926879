import { createContext } from "react";

export const language = {
    language: "",
    languages: [],
    translate() {},
};

const LanguageContext = createContext(language);

export default LanguageContext;

export const congestion = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.36 22.97">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g id="Group_3009" data-name="Group 3009">
    <path id="Path_3651" data-name="Path 3651" class="cls-1" d="M20.79,21.33H13.13v.51A1.08,1.08,0,0,1,12.08,23H9.92a1.08,1.08,0,0,1-1.16-1,.49.49,0,0,1,0-.12V20.42c0-1.21,0-2.43,0-3.64a1.3,1.3,0,0,1,.11-.43c.37-1,.75-2.08,1.14-3.11.29-.77.61-1.53.9-2.31a2.59,2.59,0,0,1,1.47-1.47A8.22,8.22,0,0,1,15.3,8.8a25.64,25.64,0,0,1,3.28,0,8.05,8.05,0,0,1,3.17.75,2.24,2.24,0,0,1,1.18,1.24c.73,1.93,1.47,3.84,2.2,5.76a.83.83,0,0,1,0,.22v5a1.09,1.09,0,0,1-1,1.18H22a1.11,1.11,0,0,1-1.18-1v-.15C20.79,21.64,20.79,21.49,20.79,21.33ZM17,14.75h0c1.66,0,3.34,0,5,0a3.58,3.58,0,0,0,.78-.15.33.33,0,0,0,.23-.38,2.68,2.68,0,0,0-.13-.56c-.31-.84-.65-1.68-1-2.52a.92.92,0,0,0-.39-.48A5.3,5.3,0,0,0,19.55,10a20.67,20.67,0,0,0-4.91,0,5.55,5.55,0,0,0-2.1.66,1.06,1.06,0,0,0-.51.49C11.66,12,11.3,13,11,13.9a.49.49,0,0,0,.17.68.4.4,0,0,0,.24.07,3.55,3.55,0,0,0,.86.1Zm-4.27,3.87v0l.36-.05a.79.79,0,0,0,.59-.94.86.86,0,0,0-.15-.32,1,1,0,0,0-.77-.34H11.58a.85.85,0,0,0-.23,0,.54.54,0,0,0-.38.67.41.41,0,0,0,.15.24.83.83,0,0,0,.26.19,5.19,5.19,0,0,0,.66.35c.22.07.43.14.65.19Zm9.06-1.68h-.41a2.69,2.69,0,0,0-.4,0,.85.85,0,0,0-.7.76.81.81,0,0,0,.66.81,1.46,1.46,0,0,0,.55,0A3.39,3.39,0,0,0,22.67,18a.54.54,0,0,0,.27-.66.6.6,0,0,0-.6-.4h-.59Z"/>
    <path id="Path_3652" data-name="Path 3652" class="cls-1" d="M10,10.38l-.46,1.19c-.6,1.58-1.2,3.17-1.78,4.77a2.9,2.9,0,0,0-.07.6H4.38v.47a1.1,1.1,0,0,1-1,1.17.43.43,0,0,1-.16,0H1.15a1.09,1.09,0,0,1-1.15-1V12.37a1.4,1.4,0,0,1,.06-.28c.36-.95.71-1.91,1.07-2.86S1.84,7.41,2.18,6.5A2.52,2.52,0,0,1,3.59,5.1a7.37,7.37,0,0,1,2.52-.64,22,22,0,0,1,4.44,0,7.18,7.18,0,0,1,2.49.7,2.21,2.21,0,0,1,1.15,1.25c.15.43.33.86.5,1.3l-1,.16s-.09-.06-.1-.1a9,9,0,0,1-.34-.86,1.36,1.36,0,0,0-.88-.88,7,7,0,0,0-2.07-.5,21.71,21.71,0,0,0-4.34,0,5.62,5.62,0,0,0-2.11.64,1.27,1.27,0,0,0-.61.68c-.32.86-.66,1.71-1,2.57a.54.54,0,0,0,.18.74.56.56,0,0,0,.27.08,5.47,5.47,0,0,0,.9.08H10ZM4,14.24v0h.17a.88.88,0,0,0,.77-.63.74.74,0,0,0-.37-.84A1.28,1.28,0,0,0,4,12.58c-.39,0-.78,0-1.16,0a.62.62,0,0,0-.63.41.6.6,0,0,0,.32.68,7,7,0,0,0,.82.42,4.08,4.08,0,0,0,.6.16Z"/>
    <path id="Path_3653" data-name="Path 3653" class="cls-1" d="M23.71,9.85l1-.51A1,1,0,0,0,25,9.17a.52.52,0,0,0,.18-.57.55.55,0,0,0-.47-.39l-.44,0h-.74a1,1,0,0,0-1,.5l-.43-.21a8.63,8.63,0,0,0-3-.73c-1-.06-2-.06-3-.08a.23.23,0,0,1-.26-.17c-.16-.44-.34-.88-.51-1.32,0,0,0-.1,0-.17h8.56c.25,0,.51,0,.77,0a1.06,1.06,0,0,0,.23,0c.37-.11.47-.27.38-.65a4.41,4.41,0,0,0-.23-.7c-.26-.7-.55-1.4-.81-2.1a1.11,1.11,0,0,0-.59-.65,6.23,6.23,0,0,0-2.11-.6,21.34,21.34,0,0,0-4.52,0,5.66,5.66,0,0,0-2.13.64,1.29,1.29,0,0,0-.62.7c-.2.55-.42,1.09-.64,1.63l-1-.4s0-.09,0-.14c.18-.46.38-.93.54-1.4A2.53,2.53,0,0,1,14.53.74,7.9,7.9,0,0,1,17.15.08a25,25,0,0,1,3.67,0,8.6,8.6,0,0,1,2.84.62,2.48,2.48,0,0,1,1.5,1.47c.71,1.9,1.45,3.79,2.17,5.68a.78.78,0,0,1,0,.22v5a1.09,1.09,0,0,1-1,1.14h-.77a.17.17,0,0,1-.2-.13C25,13,24.57,12,24.16,11,24,10.57,23.86,10.2,23.71,9.85Z"/>
  </g>
</svg>`];



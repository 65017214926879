export const transfers = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.28 19.77">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Icon_open-fork" data-name="Icon open-fork" class="cls-1" d="M3.7,0A3.7,3.7,0,0,0,0,3.7,3.65,3.65,0,0,0,2.47,7.18v5.41A3.63,3.63,0,0,0,0,16.07a3.71,3.71,0,0,0,7.41,0,3.64,3.64,0,0,0-2.08-3.31,1.23,1.23,0,0,1,.84-.39h4.94a3.74,3.74,0,0,0,3.7-3.7V7.21a3.65,3.65,0,0,0,2.47-3.48,3.71,3.71,0,1,0-7.41,0,3.65,3.65,0,0,0,2.47,3.48V8.66A1.22,1.22,0,0,1,11.13,9.9h-5a3.93,3.93,0,0,0-1.23.22V7.18A3.64,3.64,0,0,0,7.41,3.7,3.7,3.7,0,0,0,3.71,0Z"/>
</svg>`];


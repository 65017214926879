export const modalSplit = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.77 18.77">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Icon_material-call-split" data-name="Icon material-call-split" class="cls-1" d="M11.73,0l2.69,2.69L11,6.07l1.67,1.66,3.38-3.38L18.77,7V0ZM7,0H0V7L2.69,4.35,8.21,9.87v8.9h2.35V8.9L4.35,2.69Z"/>
</svg>`];

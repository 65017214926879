export const distanceTravelled = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.37 18.24">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <path id="Icon_awesome-road" data-name="Icon awesome-road" class="cls-1" d="M27.23,16.08,20.59.88A1.45,1.45,0,0,0,19.27,0H14.64l.11,1.1a.37.37,0,0,1-.33.42H13a.38.38,0,0,1-.38-.38v0L12.73,0H8.1A1.45,1.45,0,0,0,6.78.89L.14,16.08a1.53,1.53,0,0,0,.74,2,1.65,1.65,0,0,0,.58.14h9.35l.49-4.64a.75.75,0,0,1,.75-.68h3.27a.76.76,0,0,1,.76.68l.49,4.64h9.34a1.52,1.52,0,0,0,1.46-1.57A1.7,1.7,0,0,0,27.23,16.08ZM12.37,3.38A.39.39,0,0,1,12.75,3h1.87a.4.4,0,0,1,.38.34l.21,2.07a.57.57,0,0,1-.5.63h-2a.58.58,0,0,1-.57-.57.13.13,0,0,1,0-.06Zm2.63,8H12.37a.76.76,0,0,1-.76-.76.22.22,0,0,1,0-.08l.24-2.28a.75.75,0,0,1,.75-.68h2.15a.75.75,0,0,1,.75.68l.24,2.28a.76.76,0,0,1-.67.84Z"/>
</svg>`];


import { publicTransportComfort } from './public-transport-comfort.js';
import { travelTime } from './travel-time.js';
import { conditionOfPtVehicles } from './condition-of-pt-vehicles.js';
import { affordability } from './affordability.js';
import { sexualHarassment } from './sexual-harassment.js';
import { distanceTravelled } from './distance-travelled.js';
import { co2Emissions } from './co2-emissions.js';
import { vehicleOccupancy } from './vehicle-occupancy.js';
import { trafficFatalities } from './traffic-fatalities.js';
import { access } from './access.js';
import { driverBehaviour } from './driver-behaviour.js';
import { transfers } from './transfers.js';
import { crimeOnPt } from './crime-on-pt.js';
import { periodOfTravel } from './period-of-travel.js';
import { congestion } from './congestion.js';
import { publicTrReliability } from './public-tr-reliability.js';
import { modalSplit } from './modal-split.js';

const icons = {
    publicTransportComfort,
    travelTime,
    conditionOfPtVehicles,
    affordability,
    sexualHarassment,
    distanceTravelled,
    co2Emissions,
    vehicleOccupancy,
    trafficFatalities,
    access,
    driverBehaviour,
    transfers,
    crimeOnPt,
    periodOfTravel,
    congestion,
    publicTrReliability,
    modalSplit,
};

export default icons;

export const mobility = ["60 60", `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.174 34.271">
    <defs>
      <style>.a{fill:#242121;}</style>
    </defs>
    <g transform="translate(-1641.098 -359.309)">
      <g transform="translate(1641.098 359.309)">
        <path class="a" d="M1923.973,359.309a15.258,15.258,0,0,1,1.98.543,7.176,7.176,0,0,1,4.56,5.759,9.327,9.327,0,0,1-.829,5.2,18.966,18.966,0,0,1-3.138,4.974c-.781.911-1.626,1.769-2.47,2.623a1.1,1.1,0,0,1-1.709.017,23.78,23.78,0,0,1-5.449-7.232,10.517,10.517,0,0,1-1.087-4.765,7.424,7.424,0,0,1,6.357-7.047,1.755,1.755,0,0,0,.249-.07Zm1.2,7.18a1.476,1.476,0,0,0,.388-1.776,1.6,1.6,0,0,0-1.6-.933c-.646,0-1.291-.016-1.937-.012-.789.005-1,.224-1,1.028,0,.657,0,1.314,0,1.971s-.009,1.336.007,2c.013.572.255.822.82.831.768.013,1.537.019,2.3-.016a1.718,1.718,0,0,0,1.728-1.218A1.591,1.591,0,0,0,1925.175,366.489Z" transform="translate(-1897.436 -359.309)"/>
        <path class="a" d="M1641.1,514.744a7.387,7.387,0,0,1,14.57-1.916,8.044,8.044,0,0,1-.112,4.056,17.583,17.583,0,0,1-3.8,6.817c-.752.892-1.582,1.72-2.4,2.549a1.106,1.106,0,0,1-1.733.005,23.627,23.627,0,0,1-5.442-7.237A10.554,10.554,0,0,1,1641.1,514.744Zm10.26,2.023a3.189,3.189,0,0,0-.109-.4q-.355-.916-.727-1.824c-.331-.811-.652-1.628-1.01-2.427a.914.914,0,0,0-1.066-.557.989.989,0,0,0-.8.718q-.839,2.12-1.668,4.243a.965.965,0,0,0-.06.292.732.732,0,0,0,.547.744.645.645,0,0,0,.8-.371,5.753,5.753,0,0,0,.306-.737.312.312,0,0,1,.353-.262c.467.017.935.014,1.4,0a.3.3,0,0,1,.337.241,6.621,6.621,0,0,0,.347.791.652.652,0,0,0,.79.335A.764.764,0,0,0,1651.358,516.768Z" transform="translate(-1641.098 -497.258)"/>
        <path class="a" d="M1733.341,685.393a1.312,1.312,0,0,1-1.059-.784,1.1,1.1,0,0,1,.3-1.218,2.388,2.388,0,0,1,.355-.247l18.014-10.811a1.285,1.285,0,0,1,1-.247,1.089,1.089,0,0,1,.864.871,1.049,1.049,0,0,1-.408,1.094,2.788,2.788,0,0,1-.333.22q-8.964,5.38-17.93,10.755A7.686,7.686,0,0,1,1733.341,685.393Z" transform="translate(-1726.109 -651.122)"/>
        <path class="a" d="M2016.65,447.58c.439,0,.839.014,1.238,0a.5.5,0,0,0,.507-.517.491.491,0,0,0-.43-.58c-.434-.043-.875-.011-1.315-.011Zm0-2.361c.393,0,.758.016,1.121-.005a.458.458,0,0,0,.461-.476.451.451,0,0,0-.441-.493c-.372-.028-.747-.007-1.141-.007Z" transform="translate(-1991.51 -438.545)"/>
        <path class="a" d="M2016.65,478.6v-1.113c.44,0,.881-.032,1.315.011a.491.491,0,0,1,.43.58.5.5,0,0,1-.507.517C2017.489,478.612,2017.089,478.6,2016.65,478.6Z" transform="translate(-1991.51 -469.563)"/>
        <path class="a" d="M2016.695,445.219v-.981c.394,0,.769-.021,1.141.007a.451.451,0,0,1,.441.493.458.458,0,0,1-.461.476C2017.453,445.235,2017.088,445.219,2016.695,445.219Z" transform="translate(-1991.552 -438.545)"/>
        <path class="a" d="M1746.564,602.723l.493,1.357h-.978Z" transform="translate(-1739.051 -586.428)"/>
      </g>
    </g>
  </svg>
`];


import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./scss/style.scss";
import "@gometro/mobility-observatory-visualization-module/dist/styles.css";
import { getSupportedLanguageFromString} from "./utils/getLanguageFrom";

import { setParamFromString } from "./utils/localParamStorage";

// Navigation
import navigation from "./modules/_nav";

// Routes
import observatoryRoutes from "./modules/observatory/routes";

import actions from "./modules/common/store/actions";
import LanguageProvider from "./modules/common/hoc/language/LanguageProvider";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

// Containers
const Layout = React.lazy(() => import("./modules/common/containers/Layout"));

const App = props => {
    const {
        authenticated,
        completeAuthentication,
        initialisationFailed,
        initialised,
        initialise,
        initialising,
        authenticate,
        language,
    } = props;

    if (
        !authenticated &&
        window.location.search &&
        window.location.search.indexOf("code=") >= 0
    ) {
        completeAuthentication();
        return null;
    }

    if (!authenticated) {
        authenticate({
            username: process.env.REACT_APP_IDENTITY_USERNAME,
            password: process.env.REACT_APP_IDENTITY_PASSWORD,
        });
        return null;
    }

    if (initialisationFailed) {
        // TODO Show a better error page
        return <h1>Something went wrong!!</h1>;
    }

    if (initialising) {
        // TODO Show a better loading page
        return <h1>Initialising...</h1>;
    }

    if (!initialised) {
        initialise(window.location.pathname);
        return null;
    }

    /** Language From Cookies */
    const languageList = ["en-gb"];
    const intLanguageCode = language || getSupportedLanguageFromString(languageList, window.location.hash)

    /** URL Parameters for App */
    setParamFromString(window.location.hash, "back");

    return (
        <HashRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                    <Route
                        path="/"
                        name="Mobility Observatory"
                        render={props => (
                            <LanguageProvider language={intLanguageCode} languages={languageList}>
                                <Layout
                                    baseCssClass="latam"
                                    {...props}
                                    navigation={navigation}
                                    routes={observatoryRoutes}
                                />
                            </LanguageProvider>
                        )}
                    />
                </Switch>
            </React.Suspense>
        </HashRouter>
    );
};

const mapStateToProps = state => ({
    authenticated: state.common.authentication.authenticated,
    initialisationFailed: state.common.authentication.initialisationFailed,
    initialised: state.common.authentication.initialised,
    initialising: state.common.authentication.initialising,
    language: state.common.app.state.language.value,
});

const mapDispathToProps = dispatch => ({
    completeAuthentication: () =>
        dispatch(actions.authentication.completeAuthentication()),
    initialise: redirectPath =>
        dispatch(actions.authentication.initialise(redirectPath)),
    authenticate: options =>
        dispatch(actions.authentication.authenticate(options)),
});

export default connect(mapStateToProps, mapDispathToProps)(App);

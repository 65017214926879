// import React from "react";
// import { CIcon } from "@coreui/icons-react";

const baseEn = process.env.REACT_APP_OBSERVATORY_WP_URL;
const baseEs = `${baseEn}/es`;

export const sideBarLinks = [
    [baseEn, `${baseEn}/es/eshome`],
    [`${baseEn}/dataexplorer`, `${baseEs}/esdataexplorer`],
    [baseEn, `${baseEs}/eshome/`],
    [baseEn, `${baseEs}/eshome/`],
    [baseEn, `${baseEs}/eshome/`],
];

const exports = {
    header: {
        _tag: "CSidebarHeader",
        name: "sidebar.header",
        className: "latam-sidebar__header",
        _children: ["sidebar.header"],
    },
    footer: false,
    items: [
        {
            _tag: "CSidebarNavItem",
            name: "sidebar.home",
            className: "is-available",
        },
        {
            _tag: "CSidebarNavItem",
            name: "sidebar.data-explorer",
            className: "is-available",
        },
        {
            _tag: "CSidebarNavItem",
            name: "sidebar.about",
            //to: "#",
            className: "is-available",
        },
        {
            _tag: "CSidebarNavItem",
            name: "sidebar.events-and-publications",
            //to: "#",
            className: "is-available",
        },
        {
            _tag: "CSidebarNavItem",
            name: "sidebar.ts-and-cs",
            //to: "#",
            className: "is-available",
        },
    ],
};

export default exports;

export const co2Emissions = ["60 60", `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.36 20.48">
  <defs>
    <style>
      .cls-1 {
        fill: #adadad;
      }
    </style>
  </defs>
  <g>
    <path id="Path_3649" data-name="Path 3649" class="cls-1" d="M25.31,9.2a4.11,4.11,0,0,0-3.58-1.26c-.44,0-.88.13-1.33.2l-.06-.2a10,10,0,0,0-2.76-4.57,6.67,6.67,0,0,0-5-2,7.27,7.27,0,0,0-5,2.14A8.44,8.44,0,0,0,5.28,8.11c-.08.46-.13.92-.19,1.4H4.91A4.91,4.91,0,0,0,.4,12.72a6.15,6.15,0,0,0,.92,6,4.12,4.12,0,0,0,3.44,1.79H21a5,5,0,0,0,2.43-.59,6.35,6.35,0,0,0,3.14-4.13,8.36,8.36,0,0,0-.05-4.3A5.14,5.14,0,0,0,25.31,9.2Z"/>
    <path id="Path_3650" data-name="Path 3650" class="cls-1" d="M21.43,6.9a6.49,6.49,0,0,1,2.56,0A4.33,4.33,0,0,1,26.27,8c.13-.14.26-.27.37-.41A3.43,3.43,0,0,0,27,3.93,1.72,1.72,0,0,0,25.57,3a5,5,0,0,0-1,0c-.13,0-.17,0-.2-.12a4.7,4.7,0,0,0-.93-1.68A3.39,3.39,0,0,0,18.64.76l-.17.15a3.5,3.5,0,0,0-.82,1.16A7.65,7.65,0,0,1,21.43,6.9Z"/>
  </g>
</svg>`];

import actionTypes from "../actionTypes";

export const openAside = () => ({ type: actionTypes.layout.asideOpened });

export const closeAside = () => ({ type: actionTypes.layout.asideClosed });

export const toggleDarkMode = () => ({ type: actionTypes.layout.darkModeToggled });

export const toggleMobileSidebar = () => ({ type: actionTypes.layout.mobileSidebarToggled });

export const toggleSidebar = () => ({ type: actionTypes.layout.sidebarToggled });

/**
 * Export Actions
 */
export const actions = {
    openAside,
    closeAside,
    toggleDarkMode,
    toggleMobileSidebar,
    toggleSidebar,
}

import React from "react";

import LanguageContext from "../../../common/contexts/LanguageContext";
import { icons } from "../../../../assets/icons/index";

import {
    ObservatoryContext,
    Visualisation,
    formatResultsForBarChart,
    formatResultsForStackedBarChart,
} from "@gometro/mobility-observatory-visualization-module/dist/index.js";

function formatDataStatusMessage(results, translate, filter) {

    // apply selection filter
    let filteredResults = filter ? results.filter((e) => e.split === filter) : results;
    // apply insufficient filter
    filteredResults = filteredResults.filter((e) => (e.dataStatus && e.dataStatus !== "insufficient"));

    // if no data to display after filter is applied then don't
    if(filteredResults.length === 0) {
        return {
            message: translate("info.data_status.insufficient"),
            image: {
                src: "data:image/svg+xml;base64," + btoa(icons.dataStatusInsufficient[1]),
                width: "60px",
                height: "60px"
            }
        };
    }
    return null;
}

function formatResultsByIndicator(indicator, results, by, translate, filter) {

    // apply selection filter
    let filteredResults = filter ? results.filter((e) => e.split === filter) : results;
    // apply insufficient filter
    filteredResults = filteredResults.filter((e) => (e.dataStatus && e.dataStatus !== "insufficient"));

    const colours = [
        "#2f70d0",
        "#ffd799",
        "#a3a3a3",
        "#ffc500",
        "#f4a261",
        "#2a9d8e",
        "#e76f50",
        "#2fcad0",
        "#50ad50",
        "#ca2fd0",
    ];
    let result;
    const noPrefixBys = ["city", "zone"];
    const labelPrefix = noPrefixBys.includes(by)  ? "" : "option.";
    switch (indicator) {
        case "travel_time":
        case "distance_travelled":
            result = formatResultsForBarChart(filteredResults, by, true);
            return result.map(e => {
                e.label = translate(`${labelPrefix}${e.label}`);
                return e;
            });
        default:
            result = formatResultsForStackedBarChart(
                filteredResults,
                by,
                colours
            );

            result.labels = result.labels.map((e) => translate(`${labelPrefix}${e}`));
            result.datasets = result.datasets.map(e => {
                e.label = translate(e.label);
                return e;
            });
            return result;
    }
}

function getYLabel(translate, city, cityValue, option, optionValue) {
    let label;
    if (cityValue === "all") {
        label = `${translate("graph.y-label.all-cities")}`;

        if (optionValue !== "all") {
            label = `${label} - ${translate(option)}`;
        }

        return label;
    }

    label = `${translate(
        "graph.y-label.all-zones-in"
    )} ${translate(city)}`;
    if (optionValue !== "all") {
        label = `${label} - ${translate(option)}`;
    }

    return label;
}

function getXLabel(translate, indicator) {
    return translate(`graph.x-label.${indicator}`);
}

function translateLists(translate) {
    return {
        translate(string) {
            return translate(string);
        },
        translateLabel(current) {
            return {
                ...current,
                label: translate(current.label),
            };
        },
        results(current) {
            return {
                ...current,
                indicator: current.indicator.map((e) => ({
                    ...e,
                    label: translate(e.label),
                })),
            };
        },
    };
}

const ObservatoryGraph = ({
    indicator = "travel_time",
    option = { value: "all", label: "option.all" },
    onIndicatorChange,
    onOptionChange,
    onCityChange,
    enabledIndicatorList = [],
    disabledIndicatorList = [],
    city,
    zone
}) => {
    const context = React.useContext(ObservatoryContext);
    const { translate } = React.useContext(LanguageContext);
    const translateList = translateLists(translate);
    const state = {
        indicators: context.getIndicators(
            {
                active: indicator,
                enabled: enabledIndicatorList,
                disabled: disabledIndicatorList,
                excludeOther: true,
            },
            translateList.translateLabel
        ),
        options: context.getOptions(
            { active: option.value },
            translateList.translateLabel
        ),
        results: context.getResults(translateList.results),
        by: context.getSplitBy(),
        downloadUrl: context.getDownloadLink(),
        downloadName: context.getDownloadName(),
    };

    return (
        <div className="c-observatory-graph">
            <div className="c-observatory-graph__content">
                <Visualisation
                    onOptionChanged={onOptionChange}
                    onIndicatorChanged={onIndicatorChange}
                    filterLabel={translate("visualisation.filter.filter-by")}
                    visualisationData={formatResultsByIndicator(
                        indicator,
                        state.results,
                        state.by,
                        translateList.translate,
                        zone.value !== "all" ? zone.value.toLowerCase() : null
                    )}
                    options={state.options}
                    indicators={state.indicators}
                    download={{
                        downloadUrl: state.downloadUrl,
                        downloadLabel: translate("graph.download"),
                        downloadName: state.downloadName,
                    }}
                    chartOptions={{
                        yAxesLabel: getYLabel(translate, city.label, city.value, option.label, option.value),
                        xAxesLabel: getXLabel(translate, indicator),
                        chartHeight: "100%",
                        title: "Chart Title",
                        qaId: "visualisation-chart",
                        barColor: "#FFD799",
                        labelColor: "#ffffff",
                        containerClass: "observatory-c-visualisation-graph",
                    }}
                    messageOverlay={formatDataStatusMessage(state.results, translate, zone.value !== "all" ? zone.value.toLowerCase() : null)}
                />
            </div>
        </div>
    );
};
export default ObservatoryGraph;

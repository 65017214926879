import { combineReducers } from "redux"

import commonReducer from "../../modules/common/store/reducers";
import observatoryReducer from "../../modules/observatory/store/reducers";

const rootReducer = combineReducers({
    common: commonReducer,
    observatory: observatoryReducer,
});

export default rootReducer;

import actionTypes from "../actionTypes";

export const toMap = () =>
    ({ type: actionTypes.visualizationType.setMap });
export const toGraph = () =>
    ({ type: actionTypes.visualizationType.setGraph });

export const actions = {
    toMap,
    toGraph
};

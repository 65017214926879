import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
    cibGoogle,
    cilAccountLogout,
    cilApplicationsSettings,
    cilLockLocked,
    cilMapAlt,
    cilMoon,
    cilPeople,
    cilArrowCircleBottom,
    cidPeople,
    cilSpeedometer,
    cilSun,
    cilUser,
    cilChartPie,
    cisUniversalAccess,
    cisBarChart,
    cisSecurity,
    cisLeaf,
    cisChevronCircleDownAlt,
} from "@coreui/icons-pro";

import indicators from "./indicators";
import observatoryIcons from "./observatory";

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cibGoogle,
        cilAccountLogout,
        cilApplicationsSettings,
        cilLockLocked,
        cilMapAlt,
        cilMoon,
        cilPeople,
        cidPeople,
        cilSpeedometer,
        cilSun,
        cilUser,
        cilChartPie,
        cisUniversalAccess,
        cisBarChart,
        cisSecurity,
        cisLeaf,
        cisChevronCircleDownAlt,
        cilArrowCircleBottom,
    },
    indicators,
    observatoryIcons
);

import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const updateList = (state, action) => {
    return update(
        state, {
            [action.payload.target]: { $set: action.payload.list },
        },
    );
}

const reducers = {
    [actionTypes.list.updateList]: updateList
};

const initialState = {
    cities: [],
    zones: []
};

export default fromMap(reducers, initialState);

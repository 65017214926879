export const greenMobility = ["60 60",
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.603 34.222">
        <defs>
            <style>.a{fill:#242121;}</style>
        </defs>
        <path className="a"
              d="M52.972,5099.274c.488,1.892,1.005,3.759,1.448,5.644a49.769,49.769,0,0,1,1.247,8.275,29.809,29.809,0,0,1-.8,9.871,14.115,14.115,0,0,1-3.9,6.867,7.872,7.872,0,0,1-11.468-.4c-.185-.23-.221-.378.027-.607a37.617,37.617,0,0,0,8.621-12.161,43.729,43.729,0,0,0,2.306-6.32,3.66,3.66,0,0,0,.08-.381c0-.02-.025-.044-.079-.134-.189.415-.361.79-.528,1.167a44.689,44.689,0,0,1-8.476,12.658,43.316,43.316,0,0,1-12.122,8.788,14.414,14.414,0,0,1-2.266.872,1.48,1.48,0,0,1-1.567-.379,1.079,1.079,0,0,1-.291-1.121.915.915,0,0,1,.828-.668c.789-.1,1.591-.131,2.364-.3a17.536,17.536,0,0,0,5.191-2.281c.908-.544,1.786-1.136,2.676-1.708.106-.069.2-.155.391-.3-.265-.042-.437-.067-.608-.1a7.463,7.463,0,0,1-3.365-1.731,8.747,8.747,0,0,1-3.217-5.315,8.892,8.892,0,0,1,.975-5.637,17.776,17.776,0,0,1,8.342-8.083,52.527,52.527,0,0,1,7.012-2.564,18.462,18.462,0,0,0,5.2-2.271C51.668,5100.479,52.264,5099.882,52.972,5099.274Z"
              transform="translate(-25.152 -5099.274)"/>
    </svg>`
];

export const experience = ["60 60", `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.212 31.09">
    <defs>
      <style>.a{fill:#242121;}</style>
    </defs>
    <g transform="translate(-2415.62 -638.284)">
      <g transform="translate(2415.62 638.284)">
        <path class="a" d="M2422.823,669.375c-.045-2.6-.09-5.139-.135-7.714h-4.011a2.934,2.934,0,0,1-3.044-2.6,2.1,2.1,0,0,1-.012-.333q0-8.757,0-17.513a2.905,2.905,0,0,1,2.649-2.9c.187-.018.375-.028.562-.028q15.472,0,30.944,0a2.892,2.892,0,0,1,2.784,1.707,2.707,2.707,0,0,1,.272,1.209q0,8.767,0,17.534a2.932,2.932,0,0,1-2.879,2.922c-.077,0-.153,0-.23,0q-9.323,0-18.646,0a.5.5,0,0,0-.384.154q-3.819,3.679-7.645,7.35C2422.988,669.219,2422.928,669.275,2422.823,669.375Zm2.4-5.779c.094-.088.15-.138.2-.19q2.131-2.046,4.259-4.095a.523.523,0,0,1,.4-.158q9.845.006,19.689,0c.444,0,.552-.108.552-.551q0-8.631,0-17.262c0-.442-.116-.559-.552-.559h-31.11c-.428,0-.549.118-.549.54q0,3.34,0,6.679v.213l.012.258c-.005.088-.011.141-.011.194q0,5,0,10c0,.361.13.489.488.489h6.52C2425.161,660.641,2425.192,662.093,2425.225,663.6Z" transform="translate(-2415.62 -638.284)"/>
        <path class="a" d="M2786.363,821.349c-.752.792-1.451,1.534-2.16,2.266a.411.411,0,0,0-.124.462c.265.937.506,1.88.768,2.863-.106-.033-.188-.052-.266-.083-.876-.35-1.755-.7-2.625-1.06a.6.6,0,0,0-.606.032c-.785.467-1.583.913-2.377,1.365-.088.05-.18.093-.308.159.071-.7.139-1.355.206-2.012a2.334,2.334,0,0,1,.051-.464.965.965,0,0,0-.5-1.2c-.718-.491-1.386-1.056-2.133-1.634.682-.139,1.3-.267,1.926-.393.437-.088.879-.159,1.31-.27a.554.554,0,0,0,.321-.273c.41-.9.8-1.8,1.211-2.74.069.092.125.156.17.227.475.744.959,1.482,1.415,2.238a.617.617,0,0,0,.575.323C2784.24,821.2,2785.264,821.277,2786.363,821.349Z" transform="translate(-2772.297 -811.808)"/>
        <path class="a" d="M2939.44,821.8c-.54.562-1.072,1.132-1.624,1.683a.444.444,0,0,0-.115.5c.234.849.458,1.7.684,2.551.024.087.038.176.066.3-.99-.4-1.943-.772-2.887-1.168a.6.6,0,0,0-.605.045c-.778.459-1.564.9-2.348,1.349-.087.05-.179.093-.324.168.06-.6.113-1.155.171-1.707.047-.444.116-.886.139-1.331a.5.5,0,0,0-.2-.339c-.766-.606-1.542-1.2-2.315-1.794-.04-.031-.077-.067-.162-.141.549-.115,1.055-.223,1.563-.326.549-.111,1.1-.2,1.645-.335a.589.589,0,0,0,.344-.292c.37-.8.715-1.609,1.07-2.416.041-.092.086-.181.151-.316.4.623.763,1.208,1.14,1.787.214.328.375.758.682.939a2.765,2.765,0,0,0,1.13.12c.384.027.768.047,1.151.071s.74.05,1.18.08l-.537.569Z" transform="translate(-2916.326 -811.688)"/>
        <path class="a" d="M3082.223,820.106c1.118-.225,2.236-.446,3.351-.685a.479.479,0,0,0,.261-.259c.361-.792.707-1.591,1.059-2.387.045-.1.095-.2.162-.348.271.421.523.811.773,1.2.291.455.569.918.876,1.362a.555.555,0,0,0,.364.209c1.065.085,2.131.149,3.264.224-.1.119-.168.2-.241.28-.64.668-1.28,1.337-1.927,2a.363.363,0,0,0-.1.41c.253.908.488,1.821.728,2.733.012.046.021.093.043.191-.742-.3-1.438-.586-2.137-.866a2.81,2.81,0,0,0-1.069-.378c-.346.024-.674.333-1.006.523-.559.318-1.116.641-1.674.961-.087.05-.176.095-.3.16.1-.92.181-1.8.294-2.671a.791.791,0,0,0-.342-.863c-.784-.563-1.529-1.18-2.291-1.773-.021-.016-.056-.014-.085-.021Zm7.7,3.889c-.2-.745-.381-1.411-.54-2.083a.48.48,0,0,1,.1-.372c.338-.381.7-.74,1.054-1.108.138-.145.273-.292.448-.479-.745-.047-1.418-.1-2.093-.129a.7.7,0,0,1-.647-.379c-.326-.572-.7-1.115-1.084-1.714a9.39,9.39,0,0,0-.539,1.241,1.446,1.446,0,0,1-1.486,1.189,9.4,9.4,0,0,0-1.41.284c.616.478,1.17.915,1.731,1.341a.389.389,0,0,1,.167.42c-.089.678-.149,1.359-.224,2.084.125-.065.216-.107.3-.157.549-.316,1.1-.626,1.643-.954a.486.486,0,0,1,.492-.035C3088.5,823.424,3089.175,823.692,3089.923,824Z" transform="translate(-3059.109 -809.993)"/>
      </g>
    </g>
  </svg>
  `];


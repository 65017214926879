import actionTypes from "../actionTypes";

const reducer = (state = "graph", action) => {
    switch(action.type) {
        case actionTypes.visualizationType.setGraph:
            return "graph";
        case actionTypes.visualizationType.setMap:
            return "map";
        default: 
            return state;
    }
};
export default reducer;

import React from "react";

import LanguageContext from "../../contexts/LanguageContext";
import translateFn from "../../../../assets/locale/index";

// @todo translations should be hosted as html references
import { default as enGB } from "../../../../assets/locale/en-GB/en-GB.json";

const LanguageProvider = ({ language, children, languages }) => {
    if (! languages.includes(language)) {
        throw new Error(`Unsupported language: ${language}`);
    }
    let translate;
    if (language === "en-gb") {
        translate = translateFn(enGB);
    }

    return (
        <LanguageContext.Provider
            value={{
                language,
                languages,
                translate,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};
export default LanguageProvider;
